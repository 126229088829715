@font-face {
    font-family: Roboto-Regular;
    src: url(https://trakbond.com/website/css/fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto-Bold;
    src: url(https://trakbond.com/website/css/fonts/Roboto-Bold.ttf);
}
@font-face {
    font-family: Century Gothic;
    src: url(https://trakbond.com/website/css/fonts/CenturyGothic.ttf);
}

@font-face {
    font-family: ProximaNova-Regular;
    src: url(https://trakbond.com/website/css/fonts/proxima_ssv/ProximaNova-Regular.otf);
}
@font-face {
    font-family: Helvetica;
    src: url(https://trakbond.com/website/css/fonts/Helvetica-Font/Helvetica.ttf);
}


*{
    font-family: Helvetica, ProximaNova-Regular;
    -ms-text-size-adjust: 100%;
}
 .owl-carousel .item-video {
  height: 220px;
}
.owl-item
{
width: 430px!important;
}

.yt-border
{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0 30px;
}
body 
{
    color: #3b3b3b;
    font-family: 'Century Gothic';
    font-size: 13px;
    line-height: inherit;
    margin: 0;
    padding: 0;
    font-weight: 400;
}
.mobilesection
{
    display: none;
}
p
{
    font-family: ProximaNova-Regular;
    font-size: 18px;
    line-height: 140%;
}

sup 
{
   top: 0.09em;
    position: relative;
    line-height: 0;
    vertical-align: baseline;
    font-size: 12px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

/* Headings and Pargraph tags style*/
.tb-para {
    color: #3b3b3b;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.section-tb-features {
    margin: 30px 0 0 0;
}

.tb-features-margin {
    margin: 20px 0;
    text-align: center;
}

.tb-features-margin2 {
    margin: 100px 0;
    text-align: center;
}

.tb-mobile-button {
    max-width: 70%!important;
    text-align: center!important;
    padding: 15px 30px!important;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 0px!important; 
  padding-left: 0px!important;
}

.tb-mobile-img {
   padding-left: 10px!important;
   padding-right: 10px!important; 
}

/*Card starting from left*/
.tb-card-left {
    padding-right: 10px!important;  
}

/*Card starting from right*/
.tb-card-right {
    padding-left: 10px!important;  
}

}


.tb-center-row {
    display:table;
}
.tb-center {
    display:table-cell;
    vertical-align:middle;
    float:none;
}


/* Global level Media query */

/* All devices */

.tb-item-techpro-elders
{
  border: 3px solid #0aa595!important;
  border-radius: 50px;
}

.tb-item-techpro-elders.table::before {
    border: 1px dashed #0aa595;
}

.tb-item-techpro-kids
{
  border: 3px solid #27a9d5!important;
  border-radius: 50px;
}

.tb-item-techpro-kids.table::before {
    border: 1px dashed #27a9d5;
}

.tb-item-techpro-specialchild
{
  border: 3px solid #bd054a!important;
  border-radius: 50px;
}

.tb-item-techpro-specialchild.table::before {
    border: 1px dashed #bd054a;
}


.tb-highlight-colorprimary {
    color: #E40D36!important;
    font-weight: bold;
}

.tb-highlight-lightblue {
    color: #27a9d5!important;
    font-weight: bold;
}

.tb-highlight-lightgreen {
    color: #99cc00!important;
    font-weight: bold;
    font-size: 110%;
}

.tb-highlight-teal {
    color: #0aa595!important;
    font-weight: bold;
    font-size: 110%;
}

.tb-highlight-lightteal {
    color: #33cccc!important;
    font-weight: bold;
    font-size: 110%;
}

.tb-highlight-white {
    color: #FFFFFF!important;
    font-weight: bold;
    font-size: 110%;
}

.tb-highlight-lightred {
    color: #c05072!important;
    font-weight: bold;
    font-size: 110%;
}

.tb-highlight-red {
    color: #bd054a!important;
    font-weight: bold;
    font-size: 110%;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

/* Headings and Pargraph tags style*/
.tb-para {
    color: #3b3b3b;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.section-tb-features {
    margin: 30px 0 0 0;
}

.tb-features-margin {
    margin: 20px 0;
    text-align: center;
}

.tb-features-margin2 {
    margin: 100px 0;
    text-align: center;
}

.tb-mobile-button {
    max-width: 60%!important;
    text-align: center!important;
    padding: 15px 30px!important;
}

.tb-mobile-img {
   padding-left: 10px!important;
   padding-right: 10px!important; 
}

/*Card starting from left*/
.tb-card-left {
    padding-right: 10px!important;  
}

/*Card starting from right*/
.tb-card-right {
    padding-left: 10px!important;  
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 0px!important;
  padding-left: 0px!important;
}

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

/* Headings and Pargraph tags style*/
.tb-para {
    color: #3b3b3b;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 80% !important;
}

.section-tb-features {
    margin: 30px 0 0 0;
}

.tb-features-margin {
    text-align: center;
}

.tb-features-margin2 {
    margin: 100px 0;
    text-align: center;
}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

/* Headings and Pargraph tags style*/
.tb-para {
    color: #3b3b3b;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 80% !important;
}

.section-tb-features {
    margin: 30px 0 0 0;
}

.tb-features-margin {
    text-align: center;
}

.tb-features-margin2 {
    margin: 100px 0;
    text-align: center;
}

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

.section-tb-features {
    margin: 30px 0 0 0;
}

.tb-features-margin {
    text-align: center;
}

.tb-features-margin2 {
    margin: 100px 0;
    text-align: center;
}

}





/*Warranty and Return Policy*/


.section-17 {
       margin: 70px 180px;
}
.sign-up-2 {
    padding: 30px 12% 50px;
    border-color: #4a4a4a;
    border-top-style: none;
    border-top-width: 1px;
    background-color: #f5f5f5;
    color: #999;
}
.section.footer-new.less-top-padding {
    padding-top: 20px;
}
.section.footer-new {
    padding: 40px 10%;
    background-color: #1c3b67;
}
.section {
    display: flex;
    margin-top: 0;
    padding: 60px 5%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}
.div-flex-h {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
strong {
    font-weight: 700;
}
.legal-copy li, .legal-copy p {
    font-size: 16px;
    line-height: 28px;
}
 p{
    margin-bottom: 10px;
}
 ul {
    margin-bottom: 10px;
}
.w-richtext:after, .w-richtext:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}
.blue-heading {
    font-size: 45px;
    line-height: 55px;
    font-weight: 400;
}
.blue-heading, .blue-heading.small.futura-font {
    font-family: futura-pt,sans-serif;
    color: #c51c29;
}
/*Warranty and Return Policy*/

img
{
    margin:auto;
}
.kid-img
{
    max-width: 90%;
}
.logo .img-logo
{
    width: 170px;
    height: 106px;
}

.product-item-trail
{
    margin-top: 86px;
}

.product-thumb .trail-img 
{
    width: 175px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 190px;
    margin: auto;
    display: block;
}

.product-thumb .trailplus-img
{
    width: 250px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 266px;
    margin: auto;
    display: block;
}

.top-header1_new
{
    padding: 13px 0;
    background-color: #3b3b3d;
}
.header-intro_new
{
    margin: 0;
    color: #1cefe5;
    text-align: center;
}
.logo.logo1_new 
{
    margin:0px;
    position: relative;
    text-align: center;
    width: 170px;
    z-index: 1000;
}
.header-intro_new .lnr_new 
{
    color: #1cefe5;
}
.a_new
{
    color: #1cefe5;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
.top-info4 li .lnr_new 
{
    color: #1cefe5;
}
.bg-color2_new 
{
    background: #fff;
    /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);*/
}
.lnr_new
{
    font-family: 'Linearicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1cefe5;
}
.main-nav > ul > li > a 
{
    color: #616162;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    display: block;
}
.main-nav > ul > li.menu-blank-link {
    width: 50px;
}
.main-nav > ul > li.menu-blank-link-2 {
    width: 100px;
}
.mega-menu.mega-menu-v2 
{
    padding: 0 0 0 20px;
}
.mega-menu-v2 .sidebar-mega-menu 
{
    background: url() no-repeat top left;
    background-size: 100% 100%;
    background-color: #434344;
}
.main-nav.main-nav1 > ul > li > .a-menu-left-1
{
   margin-left: 80px;
}
.main-nav.main-nav1 > ul > li > a:hover 
{
    color: #c1272d;
}

.main-nav.main-nav1 > ul > li > .a-menu-left-1:hover 
{
    color: #c1272d;
}
.main-nav.main-nav1 > ul > li > .a-menu-left-2 
{
   margin-left:180px;
}

.li-margin
{
    margin-left: 150px;
}

.main-nav .sub-menu-myaccount 
{
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  left: 150px;
  list-style: outside none none;
  margin: 30px 0 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  visibility: hidden;
  width: 200px;
  z-index: 999;
  text-align: left;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.main-nav .main-menu-myaccount 
{
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 5px 5px 0 rgb(105, 104, 104);
    left: 0px;
    list-style: outside none none;
    margin: 10px 0 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 200px;
    z-index: 999;
    /* text-align: left; */
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}

.img-login
{
    height: 20px;
    margin-top: -10px;
}

.img-contact
{
    height: 20px;
    margin-top: -10px;
}
.scroll-fixed.nav-fixed 
{
    left: 0;
    position: fixed;
    top: -100px;
    width: 100%;
    z-index: 999999;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    background-color: #fff;
}
.scroll-fixed.nav-fixed .main-nav.main-nav1 
{
    margin: -60px 0 0;
}

.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 80px;
  width: 130px;
}

.scroll-fixed.nav-fixed .main-nav > ul > li > a 
{
    height: 50px;
    line-height: 50px;
    color: #616162;
}
.scroll-fixed.nav-fixed .main-nav > ul > li > .a-menu-left-1 
{
   margin-left:170px;
}
.scroll-fixed.nav-fixed .main-nav > ul > li > .a-menu-left-2 
{
   margin-left:180px;
}
.scroll-fixed.nav-fixed .mini-cart-box.mini-cart1, .scroll-fixed.nav-fixed .box-search-hover.search-hover1 
{
    margin-top: 10px;
}
.inner-banner-info1 > h2 span 
{
    background: #b80016;
}
.main-nav .toggle-mobile-menu 
{
    background-color: #38383a;
    margin-left: 10px;
}

/*Cart Image*/

.img-cart
{
  height: 24px;
}
.mini-cart1 .mini-cart-number 
{
    background: #3a3a3c;
}
/*Cart Image*/
.inner-banner-info1 
{
    color: #fff;
    text-align: right;
    text-transform: uppercase;
    max-width: 800px;
    float: left;
}
.inner-banner-info1 .img_new 
{
    max-width: 100%;
    float: left;
    margin-bottom: 10px;
}
.banner-6 .btn-trail 
{
    position: absolute;
    top: 47%;
    left: 44%;
    -ms-transform: translate(-50%, -50%);
    color: #404042;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
}
.p-banner
{
    font-size: 30px;
    font-weight:bold;
}
.h4-banner
{
    font-size:42px;
    font-weight:bold;
    margin-top: -10px;
    margin-bottom: -3px;
}
.strong-banner
{
    font-size:70px;;
    font-weight:bold;
}
.wecome-content-trail .a-learnmore 
{
    display: inline-block;
    background-color: transparent;
    color: #3b3b3b;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
    border: 1px solid #3b3b3b;
}
.wecome-content-trail .a-learnmore:hover 
{
    background-color: #d51c27;
    color: #fff;
    border:1px solid #fff;
}
.wecome-content-trail .a-shopnow 
{
    display: inline-block;
    background-color: #3a3a3c;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 10px 0;
    font-weight: 500;
    cursor:pointer;
}
.wecome-content-trail .a-shopnow-why-bond 
{
    display: inline-block;
    background-color: #3a3a3c;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 10px 0;
    font-weight: 500;
}

.wecome-content-trail .a-shopnow-why-bond:hover 
{
    background-color: #c1272d;
}

.wecome-content-trail .a-shopnow-wearbond 
{
    display: inline-block;
    background-color: #3a3a3c;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 0px 0 0;
    font-weight: 500;
}
.wecome-content-trail .a-shopnow:hover 
{
    background-color: #c1272d;
}
.wecome-content-trail .a-shopnow-wearbond:hover 
{
    background-color: #c1272d;
}

.p-ourproducts
{
    margin: 0 0 10px;
    letter-spacing: 0px;
    font-size: 18px;
}

.p-ourproducts-1
{
    text-align: center;
    margin-top: 15px;
    font-size: 17px;
}
.zoom-rotate .adv-new-thumb-link > img 
{
    transform: scale(1) rotate(0);
    -webkit-transform: scale(1) rotate(0);
    height: 485px;
}
.img-whybond
{
  height: 446px;
  width:570px;
}
.img-why-bond
{
    margin-top:47px;
}

.img-howtowear
{
    height: 485px;
}

.container .whybond-1 
{
    position: absolute;
    top: 20%;
    left: 85%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0px 0px;
    border: 0px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    opacity: 0.7;
    width:200px;
}
.wecome-content-trail .a-why-bond 
{
    display: inline-block;
    background-color: #d51c27;
    color: #000;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
    border: 1px solid #000;
    background-color: transparent;
}
.wecome-content-trail .a-why-bond:hover 
{
    background-color: #bb0812;
    color: #fff;
    border: 1px solid #fff;
}
.h2-why-bond
{
  font-size:30px;
 color:#f1cca0;
}
.p-why-bond
{
    font-weight:normal;
}
.h2-why-bond-1 
{
    font-size: 40px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top:-10px;
}
.h5-why-bond-1 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: 600;
}
.h5-why-bond-2 
{
    font-size: 25px;
    color: #3b3b3b;
    font-weight: 600;
}
.p-why-bond-1
{
    font-weight:normal;
    margin-top:10px;
}
.p-why-bond-2 
{
    font-weight: normal;
    margin-top: 10px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
}
.item-adv6 .banner-info
{
    max-width: 320px;
    left: 20px;
    top: 270px;
    right: auto;
}
/*Video Testimonials*/
.content-page-testmonials 
{
    margin-bottom: 50px;
    margin-top: 90px;
}
.thumb-customer-saying 
{
    display: table-caption;
    width: auto; /*80px*/
    align-items: center;
    margin: auto;
    padding-bottom: 15px;
}

a.video 
{
 /* float: left;*/
  position: relative;
}
a.video span 
{
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../images/video_testimonials/play.png") no-repeat;
  background-position: 50% 50%;
   background-size: 50px Auto;
}

.img-testimonials
{
    border: 5px solid #fffbfb;
    border-radius: 850px;
    box-shadow: 2px 2px 2px #ccc;
}
.info-customer-saying .desc 
{
    position: relative;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-family:'Roboto-Regular';
    margin-top: 10px;
}
.info-customer-saying > h3 
{
    margin: 10px 0 5px;
    text-align: center;
}
.info-customer-saying > h3 a
{
    font-size: 16px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    color: #3a3a3c;
}

.silver 
{
    color: #3a3a3c;
    text-align: center;
    font-family: 'Century Gothic';
    text-transform: capitalize;
    font-style: italic;
    padding-bottom: 15px;
}
.info-customer-saying
{
    display: table-cell;
    padding-left: 10px;
    padding-right: 20px;
    position: relative;
    vertical-align: top;
    text-align:center;
}

.thumb-customer-saying .img-customer
{
    width: 80px;
    height: 80px;
}

/*Video Testimonials*/
.btn-eye.bg-white 
{
    color: #fff;
    background-color: #bd054a;
    background-color: #bd054a;
    background-color: #bd054a;
}
.btn-eye.bg-white::before 
{
    color: #fff;
}
.btn-eye.bg-white::before 
{
    border-color: #fff;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
.btn-eye_custom::before {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    content: "";
    font-family: linearicons;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
}
.btn-eye_custom 
{
    border-radius:0px;
    color: #fff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px 0 55px;
    /* position: relative; */
    /* text-align: center; */
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    margin-top:4px;
}
.btn-eye_custom .bg-white_custom 
{
    color: #f9f9f9;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
}
.bg-white_custom 
{
    background: #393f41;
}
.h5_style
{
  font-size: 16px;
  text-transform: capitalize;
  color: #ee1520;
}
.p_style
{
	color: #fff;
}
.banner-adv1_new 
{
    margin-bottom: 75px;
    padding-bottom: 95px;
}
.product-item_new 
{
    border:none;
    margin-bottom: 30px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
.product-item .product-thumb-new
{
    margin: 10px 10px 20px;
    background-color: #fff;
}
.product-title > .a_1 
{
    color: #fff;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    font-weight: 700;
}
/*.item-adv6 .banner-info h2 
{
    text-transform: capitalize;
    margin: 10px 0 20px;
    color: #fff;
}*/
/*.desc_new 
{
    color: #fff;
    margin: 0;
    line-height: 24px;
    font-size: 14px;
}*/
.product-more-link4 a 
{
  background: #3b3b3b none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 1px;
  margin-left: 1px;
  width: 70px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  transform: scale(0) rotate(0deg);
  -webkit-transform: scale(0) rotate(0deg);
  opacity: 0;
}
.product-title > .a_new 
{
    color: #fff;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    font-weight: 700;
    margin-top: 15px;
}
.bt-style18_custom 
{
    box-shadow: 0 0 0 4px #FEC64D inset;
    background: #1b1d1f;
    color: #fff;
    padding-top: 11px;
}
.product-item4 .product-thumb-link {
    padding: 50px;
}
.product-thumb .img_1 
{
    width: 60%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 250px;
}
.banner-info_2
{
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 25em;
    z-index: 9;
}
.video-container 
{
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed 
{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}
.modal-dialog 
{
    max-width: 800px;
    margin: 30px auto;
}
.modal-body 
{
  position:relative;
  padding:0px;
}
.close 
{
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}
.about-review_new
{
    border-top: 2px solid #e5e5e5;
    margin-top: 5px;
    padding-top: 35px;
}
.brand-show_new
{
    background: rgba(0, 0, 0, 0) url(../images/home1/bg-brand.jpg) no-repeat fixed center top;
    margin-bottom: 0px;
}
.content-page_new
{
    margin-bottom: 50px;
    margin-top: 10px;
}
.content-brand-show_new::before {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-right: 100px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0) url(../images/home1/post_7.png) no-repeat scroll right bottom;
    content: "";
    display: block;
}
.content-brand-show_new 
{
    padding: 150px 0 100px;
    position: relative;
}
.list-brand_new
{
    float: right;
    text-align: center;
    max-width: 470px;
    width: 100%;
    margin-top: -10px;
}
.form-my-account_new
{
    max-width:100%;
    width: 100%;
}
.form-my-account_new input[type="submit"]:hover {
    background: #05b0bd;
}
.form-my-account_new input[type="submit"] 
{
    background: #3b3b3b none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    height: 47px;
    margin-top: 0px;
    transition: all 0.3s ease-out 0s;
    -webkit-transition: all 0.3s ease-out 0s;
    width: 110px;
}
.radio, .checkbox 
{
    display: block;
    min-height: 20px;
    padding-left: 20px;
    margin-top: 25px;
    margin-bottom: 20px;
}
/*Footer*/
.bg-color2_new_2 
{
    background: #3a3a3c;
}
.footer-box > p 
{
    color: #fff;
}
.title-footer span 
{
    color: #fff;
}
.footer-box a 
{
    color: #fff;
}
.footer-box a:hover
{
    color: #e81c24;
}
.social-footer > a 
{
    border: 0px solid #3b3b3b;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    height: 38px;
    line-height: 38px;
    margin-right: 8px;
    text-align: center;
    width: 38px;
    background-color: #c1272d;
}
.social-footer > a:hover 
{
    color: #c1272d;
    background: #fff;
    border-color: transparent; 
}
.list-footer-box 
{
    border-bottom: 1px solid #fff;
    margin-bottom: 25px;
    padding-bottom: 4px;
    position: relative;
}
.back-to-top 
{
    background: #c1272d;
    border: 0px solid #fff;
    color: #fff !important;
}

.back-to-top::after 
{
    background: #c1272d;
    bottom:10px;
}

.back-to-top 
{
    border-radius: 50%;
    bottom: 0;
    height: 40px;
    left:10%;
    right: 50%;
    margin: auto;
    padding-top:10px;
    padding-bottom: 20px;
    position: fixed;
    width: 40px;
}

.scrollTop 
{
    background: #E40D36;
    border: 0px solid #fff;
    color: #fff !important;
    border-radius: 50%;
    position: fixed;
    right: 95%;
    bottom: 95px;
    padding: 15px;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    padding-top: 8px;
    padding-bottom: 10px;
}

.scrollTop a 
{
  font-size: 18px;
  color: #fff;
}

.social-footer 
{
    font-size: 0;
    margin-bottom: 0px;
    margin-top: -15px;
}
.copyright 
{
    color: #fff;
    display: inline-block;
    font-weight: 500;
    margin-right: 20px;
    text-transform: lowercase;
    margin-top: -10px;
    font-size: 13px;
    font-family:'Century Gothic';
}
.copyright > a 
{
    color: #fff;
}

/*TrakBond Trail Page*/
.product-title 
{
    font-size: 20px;
    margin: 0 0 10px;
    text-transform: capitalize;
}
.section-trail
{
    display: block;
    width: 100%;
    height: 100%;
}
.core-feature-content 
{
    
    padding: 40px;
    position: relative;
   /* margin-top: 120px;*/
}
.core-feature-content-timeline 
{
    padding: 80px;
    position: relative;
    /* margin-top: 120px; */
}
.styling
{
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 15px;
    text-align: center;
}
.styling_new
{
    padding-top: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
    padding-left: 50px;
}
.img-new
{
    padding-top: 70px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.img-timline
{
 height: 500px;
}
.container-trail 
{
    max-width: 100%;
    position: relative;
    width: 100%;
}
.container-trail-1 
{
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-left: 60px;
    margin-bottom: 50px;
}
.col-md-push-5-trail 
{
    left: 61.66666667%;
}
.col-md-pull-7-trail 
{
    right: 43.33333333%;
}
#welcome-area-trail 
{
    padding: 50px 0;
    margin-bottom: 10px;
}
.h1-trail
{
    color:#3a3a3c;
    font-weight:bold;
    font-size:50px;
}
.list-techpro
{
    margin: auto;
    max-width: 1024px;
    margin-top: 40px;
}
.wecome-content-trail a 
{
    display: inline-block;
    background-color: #c1272d;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
}
.wecome-content-trail a:hover 
{
    background-color: #3b3b3b;
}

.wecome-content-trail .a-facebook 
{
    display: inline-block;
    background-color: transparent;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
}

.wecome-content-trail .a-facebook:hover 
{
    background-color: transparent;
}

.wecome-content-trail .a-shop-trail 
{
    display: inline-block;
    background-color: #c1272d;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
    border: 1px solid #c1272d;
}
.wecome-content-trail .a-shop-trail:hover 
{
    background-color: transparent;
    color: #3b3b3b;
    border: 1px solid #3b3b3b;
}
.wecome-content-trail-1
{
   padding-left: 50px;
}

#core-features-area-trail 
{
    padding: 0px 0 0;
    margin-top:20px;
}
#core-features-area-trail-1 
{
    padding: 7px 0 0;
    padding-bottom:20px;
}

#core-features-area-trail-spec {
    padding: 30px 0 0;
    padding-bottom: 50px;
}


#core-features-area-trail-2 
{
    padding: 0px 0 0;
    margin-top:20px;
}
#core-features-area-trail-3 
{
    padding-top: 70px;
    padding-bottom: 20px;
}

.h2-trail
{
    font-size:35px;
    color:#3b3b3b;
    font-weight:bold;
    font-family: 'Roboto-Regular';
}
.h2-trail-1 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 120px;
   font-family: 'Roboto-Regular';
}
.h2-trail-2 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    padding-top: 0px;
    font-family: 'Roboto-Regular';
}
.h2-trail-3 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Roboto-Regular';
}
.h2-trail-4 
{
    font-size: 20px;
    color: #3b3b3b;
    font-weight: bold;
    font-family: 'Roboto-Regular';
}
.h2-trail-5 
{
    font-size: 25px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 35px;
    font-family: 'Roboto-Regular';
}

.h-1-trail
{
    color: #3b3b3b;
    font-weight: bold;
    font-size: 25px;
}

.title30 
{
    font-weight: 700;
    margin: 0;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Century Gothic','Roboto-Regular';
    color: #3b3b3b;
}

.h2-trail-acces 
{
    font-size: 60px;
    color: #3c5f8a;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 0px;
    font-family: 'Roboto-Regular';
}

.p-acces
{
  margin-bottom: 50px;
}

.img-spec
{
    display:block;
    align-items: center;
    margin:auto;
    height:300px;
    margin-left:50px;
}

/*Kids Page*/
.row-spec
{
    background-color: #f5f5f5;
    border-left-radius: 25px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.row-spec-1
{
    background-color: #f5f5f5;
    border-left-radius: 25px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.col-spec
{
    padding-left: 70px;
}

.h2-spec
{
    font-family: 'Roboto-Regular';
    font-size: 20px;
    border-bottom: 2px solid #c1272d;
    width:200px;
    padding:5px 5px 5px 5px;
    height:32px;
}

.p-spec
{
    padding: 5px 10px 5px 1px;
    font-size: 15px;
    font-family: 'Roboto-Regular';
}

/*Kids Page*/

/*Product Page*/
.product-thumb .img-product-page-trail
{
    height: 250px;
    width: 70%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 120px;
}
/*Product Page*/


/*About Us Page*/

.title30-about-us 
{
    font-weight: 700;
    margin-top: 50px;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Century Gothic','Roboto-Regular';
    color: #3b3b3b;
    margin-bottom: 30px;
}

.title30-about-us-2 
{
    font-weight: 700;
    margin-top: 20px;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Century Gothic','Roboto-Regular';
    color: #3b3b3b;
    margin-bottom: 50px;
}

#core-features-area-trail-about-us 
{
    padding: 20px 0 0;
    padding-bottom: 20px;
}

#core-features-area-trail-about-us-1 
{
    padding: 50px 0 0;
    padding-bottom: 20px;
}

.h2-about-us
{
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto-Regular';
}

.p-about-us-1
{
    
    padding-left: 25px;
    padding-right: 25px;
}

.p-about-us-2
{
    padding-top:70px;
    padding-bottom:50px;
    padding-left: 25px;
    padding-right: 25px;
    font-size:16px;
}

.p-about-us-3
{
    padding-top:30px;
    padding-bottom:50px;
    padding-left: 25px;
    padding-right: 25px;
}

.p-about-us-4
{
    padding-top:10px;
    padding-bottom:30px;
    padding-left: 25px;
    padding-right: 25px;
    font-size:16px;
}

.strong-about-us
{
    font-size:20px;
    font-weight:bold;
}
.em-about-us
{
    font-weight: 555;
    color: #c9141b;
    font-family: 'Roboto-Regular';
}

.h3-about-us
{
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 20px;
    font-family: 'Roboto-regular';
}

.img-about-us
{
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    align-items: center;
    display: block;
}

.title14
{
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'Roboto-Regular', sans-serif;
}

.wecome-content-trail .a-about-us 
{
    display: inline-block;
    background-color: transparent;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: -20px 0 0;
    font-weight: 500;
    border: 1px solid #fff;
}
.wecome-content-trail .a-about-us:hover 
{
    background-color: #d51c27;
    color: #fff;
    border: 1px solid #fff;
}

/*About Us Page*/

/*About Us Page ver-2*/
#core-features-area-trail-about-ver-1 
{
    padding: 20px 0 0;
    padding-bottom: 20px;
    margin-top: 40px;
}

.container .btn-trail-about 
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 10px 15px;
    border: 0px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.7;
}

.wecome-content-trail .a-about 
{
    display: inline-block;
    background-color: #fff;
    color: #c9141b;
    padding: 7px 25px;
    border-radius: 25px;
    margin: 150px 0 0;
    font-weight: 600;
    border: 1px solid #fff;
}

.wecome-content-trail .a-about:hover 
{
    background-color: #d51c27;
    color: #fff;
    border: 1px solid #d51c27;
}

.about-container
{
    background-color: #f1f1f1;
    color: #3a3a3c;
    margin-top: 25px;
}
.h2-about-ver-2
{
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Roboto-Regular';
}

.p-about-ver-2 
{
    padding-top: 0px;
    padding-bottom: 37px;
    padding-left: 50px;
    padding-right: 50px;
}

.p-about-ver-2-1 
{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
}

.img-about-ver-2
{
    height: 120px;
}

.img-our-mission
{
    margin-left: 100px;
    
}

.img-voice
{
    margin-left: -115px;
    height:260px;
}

.title30-about-ver-2
{
    font-weight: 700;
    margin-top: 20px;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Century Gothic','Roboto-Regular';
    color: #3b3b3b;
    margin-bottom: 20px;
}
#core-features-area-trail-about-ver-2 
{
    padding: 10px 0 0;
    padding-bottom: 20px;
    margin-bottom: 50px;
}
.container .btn-trail-our-mission 
{
    position: absolute;
    top: 35%;
    left: 65%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 50%;
}

.container .btn-trail-technology 
{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 1px 1px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width:60%;
}

.container .btn-trail-voice
{
    position: absolute;
    top: 30%;
    left: 35%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 30px 1px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
}

/*About Us Page ver-2*/
.p-1-trail
{
    font-size: 20px;
    color: #c1272d;
    font-family: 'Century Gothic';
}
.p-2-trail
{
    color:#3b3b3b;
    font-size:16px;
}
.video-trail
{
    width: 100%;
    height: auto;
}
.video-trail-1
{
    width: 100%;
    height: 300px;
}
.video-hero--content-trail 
{
    position: relative;
    text-align: center;
    color: #FFF;
    margin: 350px 0;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
}
#mobileshow 
{ 
 display:none; 
}

/*Shop Taril Page*/

.detail-gallery .bx-pager > a 
{
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    display: inline-block;
    font-size: 13px;
    margin: 0 5px;
    position: relative;
    width: 70px;
    border-radius: 25px;
}

/*Shop Trail Page*/

/*Loader Overlay*/
 .overlay 
 {
    background-color: #FFFFFF;
    opacity: 0.6;
    filter: alpha(opacity = 60);
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width:100%;
    z-index:1;
}

/*Loader Overlay*/

/*Terms & Conditions Page*/
.h2-terms
{
    font-weight: 500;
    font-size: 35px;
    font-family: 'Roboto-Regular';
    padding-left: 2px;
    padding-bottom: 15px;
}


.h2-terms-1
{
    font-size: 23px;
    color: #3b3b3b;
    font-weight: 500;
    font-family: 'Roboto-Regular';
    padding-left: 20px;
    padding-right: 25px;
}

.strong-terms 
{
    font-size: 17px;
    font-weight: 500;
    padding-right: 15px;
    font-family:'Roboto-Regular';
}
.em-terms 
{
    font-weight: 500;
    color: #6b6767;
    font-family: 'Roboto-Regular';
}

.p-terms 
{
    color: #555;
    padding-left: 20px;
    padding-right: 20px;
    font-size:16px;
    padding-top:15px;
}

.p-terms-1
{
    padding-left: 50px;
    color: #555;
}

.p-terms-2 
{
    padding-left: 20px;
    color: #555;
    padding-top: 15px;
}

.sup-terms 
{
    top: -0.4em;
    position: relative;
    line-height: 0;
    vertical-align: baseline;
    font-size: 15px;
    font-family:'Roboto-Regular';
}

.a-terms
{
    font-family: 'Robot-Regular';
    font-weight: 550;
    color: #555;
}

.ul-terms
{
    font-size: 16px;
    color: #555;
}

.ul-terms-1
{
    font-size: 16px;
    color: #555;
}


.ul-terms li
{
    font-family: 'Roboto-Regular';
    padding: 10px 35px 10px;
}

.ul-terms-1 li 
{
    font-family: 'Roboto-Regular';
    padding: 10px 3px 10px;
}

/*Terms & Condiitons Page*/

/*Profile Page*/
.col-profile
{
    padding-left: 50px;
    padding-right: 30px;

}
.col-profile-1
{
    margin-left: 23%;
    align-items: center;
    display: block;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-shadow: 3px 3px 3px 3px #f7f4f4;
}

.carousel-indicators 
{
    position: relative;
    bottom: 10px;
    left: 47%;
    z-index: 15;
    width: 60%;
    padding-left: 0px;
    margin-left: -30%;
    text-align: center;
    list-style: none;
    padding-right:0px;
}

.carousel-indicators
{  
  top: -10px;
}

.carousel-indicators .active 
{
    width: 15px;
    height: 15px;
    margin: 0;
    background-color: #c21212;
}

.carousel-indicators li 
{
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.carousel-inner > .item-profile 
{
    margin-top: 10px;
}

.row-profile-row
{
    margin-top: 40px;
    border-top: 1px solid #efebeb;
}
.img-profile 
{
    height: 120px;
    margin: auto;
    align-items: center;
    display: block;
    margin-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
}

.img-profile-1
{
    height:60px;
    display:block;
}

.img-profile-2
{
    height: auto;
    padding: 10px;
    padding-right:0px;
    margin: auto;
    align-items: center;
    display: block;
    background-color: #efebeb;
    border-radius: 85px;
}

.h2-profile
{
    font-size: 30px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.h2-profile-1
{
    font-size: 30px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.h2-profile-1-h2
{
    padding-top: 40px;
    font-size: 40px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 30px;
}
.m40
{
    margin: 40px 0!important;
}
.h2-profile-h2 
{
    font-size: 18px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 40px;
}

.p-profile
{
    font-size: 17px;
    font-family: 'Roboto-Regular';
    padding-left: 80px;
    padding-right: 80px;
}

.p-profile-p
{
    font-size: 15px;
    font-family: 'Roboto-Regular';
    padding-left: 40px;
    padding-right: 40px;
}

.img-profile-img
{
    background-color: #efebeb;
    padding: 15px 15px 15px 15px;
    border-radius: 25px;
    margin-top: 53px;
}

.strong-profile
{
    font-family: 'Roboto-Regular';
    font-size: 17px;
   /* border: 0px solid #ccc;
    border-radius: 25px;
    background-color: #c1272d;
    color: #fff;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight:500;*/
}

.p-skip
{
    margin-left: 10px;
    color: #d51c27;
    margin-top: 5px;
    font-size: 18px;
    font-family: 'Roboto-Regular';
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
}

.a-profile
{
    display: grid;
    margin-left: 70px;
    margin-top: 20px;
}

.a-profile-1
{
 display: grid;
 margin-right: 70px;
 margin-top: 20px;
}

.wecome-content-trail .a-next 
{
    display: inline-block;
    background-color: transparent;
    color: #c21212;
    padding: 10px 25px;
    border-radius: 25px;
    margin: 80px 0 0;
    font-weight: 600;
    border: 1px solid #c21212;
    cursor:pointer;
}

.wecome-content-trail .a-next:hover 
{
    background-color: #d51c27;
    color: #fff;
    border: 1px solid #fff;
}

.wecome-content-trail .a-next-1 
{
    display: inline-block;
    background-color: transparent;
    color: #c21212;
    padding: 10px 25px;
    border-radius: 25px;
    margin: 30px 0 0;
    font-weight: 600;
    border: 1px solid #c21212;
    cursor: pointer;
}

.wecome-content-trail .a-next-1:hover 
{
    background-color: #d51c27;
    color: #fff;
    border: 1px solid #fff;
}

.wecome-content-trail .a-next-a 
{
    display: inline-block;
    background-color: #c1272d;
    color: #fff;
    padding: 10px 25px;
    border-radius: 25px;
    margin: 30px 0 0;
    font-weight: 600;
    border: 1px solid #c21212;
    cursor: pointer;
}

.wecome-content-trail .a-next-a:hover 
{
    background-color: transparent;
    color: #3a3a3c;
    border: 1px solid #c1272d;
}

.p-profile-1
{
    font-size: 17px;
    font-family: 'Roboto-Regular';
    float:left;
    display:inline-block;
}

.p-profile-2
{
  font-size:12px;
  font-family: 'Roboto-Regular';
}
.ui-datepicker
{
    z-index: 999 !important;
}

.text-success-profile
{
    font-family: 'Roboto-Regular';
    text-align: center;
    color: #044005;
}

.text-danger-profile
{
    font-family: 'Roboto-Regular';
    text-align: center;
    color: #c1272d;
}
.aa-shop-trail {
    display: inline-block;
    background-color: #998335;
    color: #fff;
    width: 75%;
    padding: 14px 0px;
    border-radius: 25px;
    margin: 20px 80px;
    font-weight: 500;
    cursor: pointer;
    font-size: 15px!important;
    text-align: center;
}

.margin-nxt
{
    margin: 90px 0;
}
.margin-nxttt
{
    margin: 190px 0;
}
.background-red
{
    background-color: #c05072!important;
}
.background-darkred2
{
    background-color: #bd054a!important;
}
.background-yellow
{
    background-color: #998335!important;
}
.background-cyan
{
    background-color: #5092c0!important;
}
.background-brown
{
    background-color: #c09572!important;
}
.background-yellow2
{
    background-color: #bcbd6b!important;
}

.background-teal
{
    background-color: #0aa595!important;
}

.background-lightteal
{
    background-color: #33cccc!important;
}

.background-lightblue
{
   background-color: #27a9d5!important; 
}

.background-colorprimary
{
   background-color: #E40D36!important; 
}

.background-lightgreen
{
   background-color: #99cc00!important; 
}

.background-white
{
   background-color: #FFFFFF!important; 
}

.background-darkred
{
background-color: #c56262!important;
}
.background-red:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-lightblue:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-colorprimary:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-lightgreen:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-yellow:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-brown:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-yellow2:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-cyan:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-Darkred:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.background-darkred2:hover
{
background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.backgrond_for_hover
{
    background-color:#c1272d!important;
}
.backgrond_for_hover:hover
{
    background-color: #fff!important;
color:#000!important;
box-shadow:0px 1px 4px 2px rgba(0, 0, 0, 0.15)
}
.h2-trail-11 {
    font-size: 26px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

/*REMOVE ME START*/
.p-22-trail {
    color: #3b3b3b;
    font-size: 16px;
    margin: 10px!important;
    text-align: center;
}
.p-222-trail {
    color: #3b3b3b;
    font-size: 16px;
    margin: 30px 0!important;
    text-align: center;
}
/*REMOVE ME END*/

.oval-image3
{
    margin-left: 115px!important;
}
/*Profile Page*/

.gallery-box {
    margin-bottom: 6px;
    position: relative;
}
.gallery-box:before {
    opacity: 0;
    content: "";
    background-color: #000;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 66%;
    width: 39%;
        border-radius: 50%;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
    cursor: pointer;
}
.gallery-box img {
        border-radius: 50%;
        height: 130px!important;
}
.vedio-thmbails
{
        margin: 20px 0 0 0;
}
.gallery-box .gallery-content {
    opacity: 0;
    position: absolute;
    top: 37%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}
.gallery-box:hover:before
{opacity:0.3;-webkit-transition:all .5s ease-out;
    -moz-transition:all .5s ease-out;
    -ms-transition:all .5s ease-out;
    -o-transition:all .5s ease-out;transition:all .5s ease-out;
    cursor: pointer;
}
.gallery-box:hover .gallery-content
{opacity:1;
    -webkit-transition:all .5s ease-out;
    -moz-transition:all .5s ease-out;-ms-transition:all .5s ease-out;
    -o-transition:all .5s ease-out;transition:all .5s ease-out;
}

.desktop-middle
{
    border-radius: 20px;
}

.shop-btn {
    display: inline-block!important;
    background-color: #c1272d!important;
    color: #fff!important;
    font-size: 17px!important;
    padding: 10px 50px!important;
    border-radius: 25px!important;
    margin: 20px 0 0!important;
    font-weight: 500!important;
    border: 1px solid #c1272d!important;
}
@media screen and (max-width: 768px) {
    #desktopshow
    {
        display:none;
    }
    #mobileshow 
    { 
      display:block; 
    }
    .mobilesection
    {
        display: block;
    }
    .desktopsection
    {
        display: none;
    }
    .index-banner
    {
        margin-top: 50px;
    }
    .h1-trail {
    font-size: 40px;
}
.owl-item {
    width: 325px!important;
}
.desktop-middle
{
    margin-left: 0px;
}
.section-17 {
    margin: 50px 15px;
}
.blue-heading {
    font-size: 30px;
    line-height: 45px;
    font-weight: 400;
}
.title30 {
    font-weight: 700;
    margin: 0;
    font-size: 23px;
    text-transform: capitalize;
    font-family: 'Century Gothic', sans-serif;
}
.shop-btn
{
    display: inline-block!important;
    background-color: #c1272d!important;
    color: #fff!important;
    font-size: 17px!important;
    padding: 10px 50px!important;
    border-radius: 25px!important;
    margin: 20px 0 0!important;
    font-weight: 500!important;
    border: 1px solid #c1272d!important;
}
.title303 {
    font-weight: 700;
    margin: 0;
    font-size: 25px;
    font-family: 'Century Gothic', sans-serif;
}
.aa-shop-trail {
    width: 93%;
     padding: 10px 30px;
      margin: 20px 20px 0;
}
.m10
{
    margin: 10px 0!important;
}
.m20
{
    margin: 10px 0!important;
}
.m40
{
    margin: 40px 0!important;
}
.p-2222-trail {
    color: #3b3b3b;
}

.icon-right-width:before
{
    width: 100%!important;
}
.oval-image
{
    width: 90%;
}
.oval-image2
{
    width: 90%;
    margin-left: 37px;
}
.wecome-content-trail-11
{
    text-align: center;
}


.trail-focus:focus, .trail-focus:hover
{
color: #c1272d!important;
}
.country-image
{
 width: 100%;
}
.icons-trail-tiny_locator2 
{
    width: 33px;
    height: auto;
      margin: 0 0px!important;
}
.countryy-image {
    width: 80%;
    margin: 0 35px;
}
.gallery-box {
    margin-bottom: 6px;
    position: relative;
}
.gallery-box:before {
    opacity: 0;
    content: "";
    background-color: #000;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 60%;
    width: 80%;
        border-radius: 50%;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
    cursor: pointer;
}
.gallery-box img {
    width: 100%;
        border-radius: 50%;
         height: 85%;
}
.vedio-thmbails
{
        margin: 20px 0 0 0;
}
.gallery-box .gallery-content {
    opacity: 0;
    position: absolute;
    top: 37%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}
.gallery-box:hover:before
{opacity:0.3;-webkit-transition:all .5s ease-out;
    -moz-transition:all .5s ease-out;
    -ms-transition:all .5s ease-out;
    -o-transition:all .5s ease-out;transition:all .5s ease-out;
    cursor: pointer;
}
.gallery-box:hover .gallery-content
{opacity:1;
    -webkit-transition:all .5s ease-out;
    -moz-transition:all .5s ease-out;-ms-transition:all .5s ease-out;
    -o-transition:all .5s ease-out;transition:all .5s ease-out;
}
}
/* ////Style the button and place it in the middle of the container/image */
.container .btn-trail 
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 10px 15px;
    border:1px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.7;
}
.container .div-trail 
{
    position: absolute;
    top: 20%;
    left: 70%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: #313132;
    padding: 10px 30px;
    margin: 20px 0 0;
}
.container .div-trail-1 
{
    
    padding-top: 50px;
    padding-bottom: 20px;
    margin: 20px 0 0;
}
.container .ul-trail 
{
    position: absolute;
    top: 60%;
    left: 70%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: #313132;
    padding: 10px 30px;
    margin: 20px 0 0;
    font-weight: 700;
}
.container .ul-trail  li
{
  padding: 7px;
}

.container .ul-trail-2  li
{
  padding: 7px;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}

.footer-trail 
{
    padding: 5px 0px 15px;
}
.bg-color-trail
{
  background-color:#f1f1f1;
}
.bg-color-trail-border
{
    border-bottom-right-radius: 90px;
    border-bottom-left-radius: 90px;
}

.core-feature-content 
{
    padding: 20px;
    position: relative;
}
.core-feature-content-timeline 
{
    padding: 20px;
    position: relative;
}
.img-new 
{
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.newsletter-form-trail 
{
    float:none;
    margin-bottom: 0px;
    max-width: 300px;
    padding-left: 15px;
    position: relative;
    width: 100%;
    border: 1px solid #d0caca;
    margin:auto;
    margin-top: 20px;
    border-radius: 25px;
}
.newsletter-form input[type="email"] 
{
    background: rgba(255, 255, 255, 0.1);
    border: medium none;
    border-radius: 4px;
    color: #3a3a3c;
    height: 40px;
    padding: 0 15px;
    width: 100%;
    border-radius: 25px;
}
.newsletter-form-trail button[type="submit"] 
{
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border: medium none;
  border-radius: 4px;
  color: #777575;
  height: 40px;
  position: absolute;
  text-transform: capitalize;
  width: 25px;
  top: 0;
  right: 0;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  font-size:20px;
}

.text-danger 
{
    color: #c9141b;
}

.icons-trail
{
    width: 30px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}
.icons-trail-1 
{
    width: 30px;
    height: auto;
    margin-bottom: 10px;
}
.icons-trail-tiny_locator 
{
    width: 30px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}
.icons-trail-tiny_locator2 
{
    width: 33px;
    height: auto;
}
.icons-trail-tiny_locator-1 
{
    width: 30px;
    height: auto;
    margin-bottom: 10px;
}
.icons-trail-live_tracking-1 
{
    width: 30px;
    height: auto;
    margin-bottom: 10px;
}
.icons-trail-help_button 
{
    width: 30px;
    height: auto;
    margin-right: 1px;
    margin-bottom: 10px;
}
.icons-trail-timeline_view-1 {
    width: 40px;
    height: auto;
    margin-right: 1px;
    margin-bottom: 10px;
}
.icons-trail-modish_design 
{
    width: 30px;
    height: auto;
    margin-right: 1px;
    margin-bottom: 10px;
}
/*TrakBond Trail Page*/
/*Trakbond Trail Detail Page*/
.main-detail-trail
{
    background: transparent;
    margin-top: 10px;
    padding: 30px 0 40px;
}
.detail-info .desc-trail 
{
    border-bottom: 0px solid #ccc;
    border-top: 0px solid #ccc;
    margin-bottom: 15px;
    padding: 15px 0;
    font-size: 16px;
}
.color-choose input[type="radio"] + label span 
{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: -1px 2px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    border-radius: 50px;
}
.product-extra-link.style2 a.addcart-link 
{
    background: #3b3b3b none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    letter-spacing: 1px;
    line-height: 36px;
    padding-left: 53px;
    position: relative;
    text-align: left;
    width: 156px;
    margin-left: 0;
    border-radius: 50px;
}
/*Trakbond Trail Detail Page*/
/*Product Detail Page*/
.about-full-protec span 
{
    border-radius: 50%;
    color: #fff;
    display: table-cell;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    width: 80px;
}
.strong_style
{
    color:#3b3b3b;
    font-size:20px;
}
.tab-detail 
{
    margin-top: 100px;
}
.product-color span 
{
  font-size: 14px;
  font-weight: 400;
  color: #86939E;
}
/* Product Color */
.color-choose div 
{
    display: inline-block;
}
.color-choose input[type="radio"] 
{
    display: none;
}
.color-choose input[type="radio"] + label span 
{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: -1px 2px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border: 0px solid #3a3a3c;
    box-shadow: 0 0px 3px 0 #585252;
}
.color-choose input[type="radio"]#red + label span 
{
    background-color: #c9154f;
}
.color-choose input[type="radio"]#blue + label span 
{
    background-color: #314780;
    z-index: 10;
}
.color-choose input[type="radio"]#black + label span 
{
    background-color: #323232;
}
.color-choose input[type="radio"]:checked + label span 
{
    /*background-image: url(https://trakbond.com/website/images/home1/check.png);*/
    background-repeat: no-repeat;
    background-position: center;
    border: 3px solid #d2d1d1;
}
/* Product Configuration */
.product-color-a span 
{
    font-size: 14px;
    font-weight: 400;
    color: #86939E;
}
/* Product Color */
.color-choose-a div 
{
    display: inline-block;
}
.color-choose-a input[type="radio"] 
{
    display: none;
}
.color-choose-a input[type="radio"] + label span 
{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: -1px 2px 0 0;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 50%; */
    border: 2px solid #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.color-choose-a input[type="radio"]#reda + label span 
{
    background-color: #C91524;
}
.color-choose-a input[type="radio"]#bluea + label span 
{
    background-color: #314780;
    z-index: 10;
}
.color-choose-a input[type="radio"]#blacka + label span 
{
    background-color: #323232;
}
.color-choose-a input[type="radio"]:checked + label span 
{
    background-image: url(http://lisenme.com/demo/color_change/images/check-icn.svg);
    background-repeat: no-repeat;
    background-position: center;
}
/*Product Detail Page*/
/*Kids Detail Page*/
   .title-detail 
   {
     font-weight: 600;
     margin: 0 0 10px;
     text-transform: capitalize;
     font-family: 'Roboto-Regular';
   }

  .inner-tab-detail ul 
  {
    list-style: outside;
    margin: 20px 30px 20px;
    padding: 0;
  }

 /*.latest-product-detail 
 {
    margin: 35px 280px 20px;
 }*/

/*Kids Detail Page*/
/*Product Detail Two*/
.attr-detail-box .product-extra-link.style2 
{
    display: block;
    margin-bottom: 20px;
}
.info-qty 
{
    background: none;
    border: 0px;
    display: inline-block;
    height:40px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-right: 0px;
    max-width: 305px;
    padding: 0px;
    position: relative;
    text-align:center;
    vertical-align: top;
    width: 100%;
}

.form-inline .form-control 
{
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    border-radius: 25px;
}

.form-group-3 
{
    margin-bottom: 15px;
    margin-right: 13.0em;
}
.mini-cart-box.mini-cart1 
{
    float: right;
    margin-top: 21px;
}
.mini-cart-button a 
{
    background: #000 none repeat scroll 0 0;
    border-radius: 25px;
    color: #fff;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-transform: capitalize;
    padding-right: 12px;
    nsition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
/*Product Detail Two*/
/*Cart Page*/
.box-header-intro 
{
    margin-bottom: 10px;
    margin-top: 5px;
}
.contact-info-page 
{
    background: #f4f4f4 none repeat scroll 0 0;
    padding: 30px 0;
    text-align: center;
    padding-bottom: 5px;
}



/*Cart Page*/
/*Shipping Page*/
.row-shadow-shipping 
{
    box-shadow: 0 0 3px #ccc;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 20px;
}
.title-shhiping-page 
{
    font-weight: 500;
    margin: 20px 0 5px;
    text-align: center;
    text-transform: capitalize;
}

.title-p
{
  margin-bottom: 80px;
  text-align: center;
  margin-top: 15px;
}

.form-my-account-new 
{
    max-width: 100%;
    width: 100%;
}
.radio-inline, .checkbox-inline 
{
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 10px;
}
.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline 
{
    margin-top: 10px;
    margin-left: 10px;
}

.radio-inline > .radio-shipping
{
    width: 1.5em;
    height: 1.5em;
    margin-top: -0px;
    margin-right: 7px;
}

.form-my-account input[type="submit"] 
{
    background: #c1272d none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    height: 40px;
    margin-top: 10px;
    transition: all 0.3s ease-out 0s;
    -webkit-transition: all 0.3s ease-out 0s;
    width: 310px;
    border-radius: 25px;
}

.form-my-account input[type="text"] 
{
    border: 1px solid #e5e5e5;
    color: #3b3b3b;
    font-weight: 700;
    height: 50px;
    padding: 0 20px;
    text-transform: uppercase;
    width: 100%;
}

.h3-shipping 
{
    margin-top: 2px;
    margin-bottom: 10px;
    font-family: 'Roboto-Regular';
}

#error-coupon
{
    color: #d51c27;
    display: inline;
    float: right;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    margin-right: 70px;
    margin-bottom: 20px;
}

.shippingerror
{
    color: #d51c27;
    display: inline;
    font-weight: bolder;
    float: right;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.title-shipping 
{
    font-family: 'Roboto-Regular';
    margin-top: 5px;
    margin-bottom: 35px;
    color: #d51c27;
    font-size: 20px;
    margin-left: 15px;
}

._1GRhLX-shipping 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 25px 0px 20px 20px;
    margin-left: 10px;
}
/*Shipping Page*/
/*Success Page*/
.item-message-box 
{
    margin-bottom: 2px;
    padding: 20px 30px;
}
.item-message-box p 
{
    font-size: 20px;
    margin: 0;
}
.h2-1
{
    font-size: 25px;
    margin-bottom: 30px;
}
.strong-1 
{
    font-weight: bold;
    padding-right: 48px;
}
.strong-2 
{
    font-weight: bold;
    padding-right: 34px;
}
.strong-3 
{
    font-weight: bold;
    padding-right: 20px;
}
.border-1
{
  border-right: #cac7c78f solid 1px;
}
._1GRhLX 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 25px 0px 20px 0px;
}
._2kIypH ._2QEGRr ._3WyGKy 
{
    
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
}
._2kIypH ._2QEGRr ._1YeuTr 
{
    margin: 16px 0;
    padding: 0;
}
.color-1
{
    background-color:#f9f9f9a6;
}
.p-1
{
  margin-top: 100px;
  font-size: 20px;
}
.p-2
{
  font-size: 20px;
}
.bt-loadmore 
{
    width: 300px;
    color: #fff;
    padding-top: 10px;
}
.bt-blue 
{
    background: #c1272d;
    color: #fff;
}
.bt-style24:hover 
{
    background: #3a3a3c;
    color: #fff;
}

.img-delivery
{
    height: 30px;
    margin-top: -10px;
}

.img-delivery-1
{
    height: 30px;
    margin-top: -10px;
    transform: rotateY(0.5turn);
}
/*Success Page*/
/*Success Page ver -2*/
._1GRhLX-success-ver-2 
{
    background-color: #f1efef;
    border-radius: 160px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    border-bottom: 1px solid #f3f1f1;
}

._1GRhLX-success-ver-2-1
{
    background-color: transparent;
    border-radius: 160px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    border: 1px solid #f3f1f1;
    padding-top: 25px;
    padding-bottom: 25px;
}

.h2_order-success-ver-2 
{
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    font-weight: 600;
}

.desc-success-ver-2
{
    color: #555;
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    font-family: 'Roboto-Regular';
    padding-bottom:30px;
}

.h2-success-ver-2 
{
    font-family: 'Roboto-Regular';
    font-size: 25px;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 20px;
    padding-left: 50;
    margin-left: 70px;
}

.h2-success-ver-2-1
{
    padding-top: 40px;
    font-family: 'Roboto-Regular';
    font-size: 20px;
}

.p-success-ver-2
{
    margin-left: 70px;
    font-family: 'Roboto-Regular';
    padding-top: 20px;
    padding-bottom: 20px;
}

.p-1-success-ver-2 
{
    margin-top: 40px;
    font-size: 20px;
    background-color: #f1efef;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 160px;
    width: 70%;
}

.strong-success-ver-2
{
    padding-right: 15px;
    padding-left: 50px;
}

.bt-link 
{
    border: medium none;
    display: inline-block;
    height: 40px;
    padding: 10px 30px;
    text-transform: capitalize;
    vertical-align: top;
    overflow: hidden;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}

.bt-radius
{
    border-radius: 25px;
}
.bt-style24:hover 
{
    background: #3a3a3c;
    color: #fff;
}
/*Success Page ver -2*/
/*Cart User Sign Up Page*/
.form-my-account input[type="email"]
{
    border: 1px solid #e5e5e5;
    color: #999;
    font-weight: 700;
    height: 50px;
    padding: 0 20px;
    text-transform:none;
    width: 100%;
}
.form-my-account input[type="password"]
{
    border: 1px solid #e5e5e5;
    color: #999;
    font-weight: 700;
    height: 50px;
    padding: 0 20px;
    text-transform:none;
    width: 100%;
}
.form-my-account textarea 
{
    border: 1px solid #e5e5e5;
    color: #999;
    font-weight: 700;
    height: 100px;
    padding: 10px 20px;
    text-transform: none;
    width: 100%;
}
.item-message-box.item-message-success 
{
    background: #cef3c6 none repeat scroll 0 0;
    color: #2ebe12;
    font-size: 18px;
}
.item-message-box-contact.item-message-success-contact 
{
    background: #3a3a3c none repeat scroll 0 0;
    color: #fff;
    font-size: 18px;
}
/*Cart User Sign Up Page*/
/*Cart User Account Page*/
._1GRhLX-1 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 15px 0px 5px 0px;
}
.h3-1
{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size:20px;
}
.h3-2 
{
    margin-top: 20px;
    margin-bottom: 20px;
}
.h2_1
{
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: 'Roboto-Regular';
    text-transform: capitalize;
    color: #c1272d;
    margin-left: 10px;
    font-size: 20px;
}

._1GRhLX-2 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 15px 0px 100px 0px;
}
._1GRhLX-3 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 15px 0px 50px 50px;
    margin-left: 10px;
}
.form-control-1 
{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 50px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.label-1 
{
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    padding-right: 10px;
}
.bt-loadmore-1
{
    width: 300px;
    color: #fff;
    padding-top: 1px;
    text-transform: capitalize;
    background-color: #c1272d;
    border-radius: 25px;
}


/*input[type="radio"]
{
    width: 2.0em;
    height: 2.0em;
    margin: -3px 10px 0 !important;
    line-height: normal;
}*/


.color-choose-cartaccount input[type="radio"] 
{
    display: none;
}

.color-choose-cartaccount input[type="radio"] + label span 
{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: -1px 10px 0 20px;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius:25px;
    box-shadow: 0 1px 1px 0 #ccc;
}

.color-choose-cartaccount input[type="radio"]#blue + label span 
{
    background-color: #fff;
    z-index: 10;
}

.color-choose-cartaccount input[type="radio"]#white + label span 
{
    background-color: #fff;
    z-index: 10;
}

.color-choose-cartaccount input[type="radio"]:checked + label span 
{
    background-image: url(https://trakbond.com/website/images/home1/circle.png);
    background-repeat: no-repeat;
    background-position: center;
}


/*Cart User Account Page*/
/*Cart User Orders Page*/
.h2_order 
{
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 20px;
    font-weight: 600;
}
.h2-order-2
{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width:50%;
}
.product-thumb .img-order 
{
    width: 70%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
._1GRhLX-order 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 0px 0px 2px 0px;
}
.row-order 
{
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 10px;
}
.row-1 
{
    margin-right: -15px;
    margin-left: -15px;
    background-color: #8080801c;
    border-bottom: 1px solid #8080801c;
}
.bt-style40-order 
{
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 20%;
    margin-left: 390px;
}
.modal 
{
    position: fixed;
    top: 5em;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    /*background-color: rgba(249, 248, 248, 0.65);*/
}
/*Cart User Orders Page*/

/*Cart User Orders Page ver 2*/
._1GRhLX-order-ver-2 
{
    background-color: #f1efef;
    border-radius: 160px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    
}

.row-order-ver-2
{
    background-color: #fff;
}

.row-order-ver-2-1
{
    margin-bottom: -25px;
}

.h2-order-2-ver-2 
{
    margin: auto;
    background-color: #fff;
    color: #3a3a3c;
    padding: 3px 20px 3px 5px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 20px;
    font-family: 'Roboto-Regular';
    width: 47%;
    margin-top: 20px;
}

.h2-order-ver-2-1
{
    font-family: 'Roboto-Regular';
    font-size: 25px;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 20px;
}

.cart-thumb-order-ver-2 
{
    display: block;
    overflow: hidden;
    width: 120px;
    margin-top: 15px;
    margin-left: 40px;
}

.img-circle-order-ver-2
{
    border-radius: 70%;
    background-color: #f9f5f5;
    padding: 10px;
}

.h4-order-ver-2
{
    margin-top: 15px;
    margin-left: 50px;
    font-family: 'Roboto-Regular';
}

.p-order-ver-2
{
    margin-top: 15px;
    margin-left: 50px;
    font-family: 'Roboto-Regular';
}

.p-order-ver-2-1
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
}

.p-order-ver-2-2
{
    margin-left: 130px;
    font-family: 'Roboto-Regular';
}
 .span-order-ver-2
 {
    background-color: #fff;
    margin-top:10px;
    margin-bottom:10px;
 }
/*Cart User Orders Page ver 2*/

/*Whybond Page*/
.bg-gray-new
{
    background-color: #fff;
}
.banner-adv1_new_4 
{
    margin-bottom: 0px;
    padding-bottom: 95px;
}
.banner-adv1 .banner-info-new 
{
    top: -120px;
    width: 400px;
}
.white-new 
{
    color: #fff;
}
/*Whybond Page*/
/*Pricing Page*/
.banner-adv-2.banner-ads6 .banner-info h2.title48 
{
    border-bottom: none;
}
.white-2 
{
    color: #fff;
}
.lnr-new 
{
    font-size: 2.0em;
}
/*Pricing Page*/

/*Login page validation*/
/*Form error*/
.input-group 
{
    position: relative;
    display: table;
    border-collapse: separate;
    margin-bottom: 10px;
}
.input-group 
{
  ~ p {
    display: block;
  }
}

label.error, label.error 
{
    color: #d51c27;
    display: inline;
    font-weight: bolder;
    float: left;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    font-weight: 500;
    font-style:none;
}

.newslettererror  
{
    color: #ed1c24;
    display: inline;
    float: right;
    font-family: 'Roboto-Regular';
    font-size: 13px;
    font-weight: 500;
    font-style:none;
}

.newslettererrorpopup  
{
    color: #ed1c24;
    display: inline;
    float: right;
    font-family: 'Roboto-Regular';
    font-size: 13px;
    font-weight: 500;
    font-style:none;
}

#error-news  
{
    color: #ed1c24;
    display: inline;
    text-align:center;
    font-family: 'Roboto-Regular';
    font-size: 13px;
    font-weight: 500;
    font-style:none;
}

.contacterror  
{
    color: #ed1c24;
    display: inline;
    float: left;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: -10px;
}
.profileerror  
{
    color: #ed1c24; 
}

.help-block 
{
    font-family: 'Roboto-Regular';
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #d51c27;
    font-size: 17px;
   font-weight:500;
}

.admin-error 
{
    color: #d51c27;
    display: inline;
    float: right;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    font-weight: 500;
    margin-top:5px;

}

.h3-error-message
{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size:18px;
    font-family:'Roboto-Regular';
}

.h3-error-message-1 
{
    margin-top: -5px;
    margin-bottom: -5px;
    font-size: 18px;
    font-family: 'Roboto-Regular';
}

.a-login 
{
    color: #3b3b3b;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    font-weight: bold;
    font-size: 17px;
}
.item-message-box.item-message-error 
{
    background: #c1272d none repeat scroll 0 0;
    color: #fff;
}
/*Form error*/
.title-tab-detail li.active a::after 
{
    background: #ed1c24 none repeat scroll 0 0;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    top: -18px;
    width: 100%;
}
.title-tab-detail li.active .a-1, .title-tab-detail li:hover .a-1 
{
    color: #fff;
    background-color: #414042;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
}
.title-tab-detail 
{
    border-bottom: 1px solid #9c9a9a;
    margin-bottom: 25px;
    padding-bottom: 13px;
}
.table-1 
{
    max-width: 100%;
    background-color: transparent;
    margin-bottom:20px;
}
.td-1 
{
    padding: 0;
    padding-right: 7px;
    font-weight: 500;
}
.td-extra
{
  padding-right: 50px;
}
.td-new-customer 
{
    font-weight: 500;
    padding-top: 10px;
    font-family: 'Roboto-Regular';
    font-size: 16px;
}

.td-forget-password 
{
    font-weight: 500;
    padding-top: 10px;
    font-family: 'Roboto-Regular';
    font-size: 16px;
}

.td-new-customer a
{
    font-size: 16px;
    font-family: 'Roboto-Regular';
}

.td-forget-password a 
{
    font-size:16px;
    font-family: 'Roboto-Regular';
    
}

.text-muted-2 
{
    color: #3b3b3b;
}
/*Login page validation*/
/*Service Register User Page*/
.row-shadow
{
  box-shadow: 0 0 3px #ccc;
}

.row-shadow-login
{
  box-shadow: 0 0 3px #ccc;
  padding-bottom: 50px;
}

.row-shadow-resetpassword
{
    box-shadow: 0 0 3px #ccc;
    padding: 30px 50px 50px 50px;
}

.account-login
{
    padding-right: 25px;
}
.account-register::before 
{
    border-right: 0px solid #e5e5e5;
    content: "";
    height: 100%;
    left: -60px;
    position: absolute;
    top: 0;
    width: 1px;
}

.form-partititon
{
    padding-top:140px;
}

.account-register 
{
    position: relative;
    width: 100%;
    padding-left: 25px;
}
.title-shop-page 
{
    font-weight: 500;
    margin: 20px 0 80px;
    text-align: center;
    text-transform: capitalize;
}

.title-shop-page-mobile 
{
    font-weight: 500;
    margin: -10px 0 50px;
    text-align: center;
    text-transform: capitalize;
}


.title-shop-page-resetpassword
{
    font-weight: 500;
    margin: 20px 0 50px;
    text-align: left;
    text-transform: capitalize;
    color:#c1272d;
    font-family: 'Roboto-regular';
}

.title
{
    font-family: 'Roboto-Regular';
    margin-top: 5px;
    margin-bottom: 35px;
    color: #d51c27;
    font-size: 20px;
}

.title-mobile
{
    font-family: 'Roboto-Regular';
    margin-top: 5px;
    margin-bottom: 35px;
    color: #d51c27;
    font-size: 25px;
}

label 
{
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 20px;
}

label-lm
{
    display: inline-block;
    margin-bottom:5px;
    font-weight: bold;
    margin-top: 5px;
}

.form-control-signup
{
    border-radius:25px;
    height:43px;
}

.form-my-account .title 
{
    margin-bottom: 26px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.form-input-register
{
   margin-top:16px; 
}

input[type="checkbox"]
{
cursor: pointer;
border-radius: 25px;
box-sizing: border-box;
position: relative;
box-sizing: content-box ;
margin-right:10px;
width: 15px;
height: 15px;
border-width: 0;
}

.servicereg_privacy input[type="checkbox"] 
{
    cursor: pointer;
    border-radius: 25px;
    box-sizing: border-box;
    position: relative;
    box-sizing: content-box;
    margin-right: 10px;
    width: 1.5em;
    height: 1.5em;
    border-width: 0;
}

.fa-user-1 
{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ed1c24;
}
.wecome-content-trail input[type="button"] 
{
    display: inline-block;
    background-color: #d51c27;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
    border: none;
    width:100%;
    height: 43px;
}
.wecome-content-trail-servicereg input[type="button"] 
{
    display: inline-block;
    background-color: #d51c27;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 0px 0 0;
    font-weight: 500;
    border: none;
    width: 100%;
    height:43px;
}
.wecome-content-trail button
{
    display: inline-block;
    background-color: #d51c27;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 5px 0 0;
    font-weight: 500;
    border: none;
    width:100%;
    height:43px;
}
.form-my-account 
{
    max-width: 100%;
    width: 100%;
}
.form-my-account input[type="button"]:hover
{
     background-color: #3b3b3b;
}
.form-my-account button:hover
{
     background-color: #3b3b3b;
}  
.form-my-account input[type="text"]
{
    border: 1px solid #c1bfbf;
    text-transform: capitalize;
    border-radius: 25px;
}

.form-my-account .user_email
{
    border: 1px solid #c1bfbf;
    text-transform: lowercase !important;
    border-radius: 25px;
}

.form-my-account input[type="submit"]:hover 
{
    background: #3a3a3c;
}

.form-my-account input[type="email"] 
{
    border: 1px solid #d51c27;
}
.form-my-account input[type="password"]
{
    border: 1px solid #c1bfbf;
}
.form-my-account input[type="checkbox"] 
{
    display: inline-block;
    width:1.2em;
    height:1.2em;
}
.form-my-account a 
{
    color: #3b3b3b;
    position: relative;
    right: 0;
    top: 0;
}
.radio-inline-1
{
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 10px;
    font-size: 17px;
}
.item-message-box.item-message-error-1 
{
    background: #ed1c24 none repeat scroll 0 0;
    color: #fff;
    font-size: 15px;
}

.wecome-content-trail .a-login-cart 
{
    display: inline-block;
    background-color: #d51c27;
    color: #fff;
    padding: 10px 25px;
    border-radius: 25px;
    margin: 20px 10px 0;
    font-weight: 500;
    cursor:pointer;
}

.wecome-content-trail .a-login-cart:hover 
{
   background-color: #3b3b3b;
   color: #fff;
}

.img-trail-id
{
    height: 20px;
}

/*Service Register User Page*/

/*Contact page*/
.row-shadow-contact 
{
    box-shadow: 2px 5px 5px #ccc;
    margin-top: 60px;
    border-top: 3px solid #ccc;
}
.contact-form
{
    margin-bottom: 15px;
}

.col-color
{
  background-color:#3a3a3c;
}

.title-contact-page 
{
    font-weight: 500;
    margin: 20px 0 80px;
    text-align: center;
    text-transform: capitalize;
}
.a-contact
{
  color:#fff;
}

.footer-phone 
{
    background: rgba(0, 0, 0, 0) url(../images/1440px/website/images/contact_form/call.png) no-repeat scroll left center;
}
.footer-address 
{
    background: rgba(0, 0, 0, 0) url(../images/1440px/website/images/contact_form/location.png) no-repeat scroll left center;
}
.footer-email 
{
    background: rgba(0, 0, 0, 0) url(../images/1440px/website/images/contact_form/email.png) no-repeat scroll left center;
}
/*Contact page*/

/*Dashboard Page*/
.h4-d
{
    margin-top: 17px;
    margin-bottom: 10px;
}
.fa-d 
{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ed1c24;
}
.a-d 
{
    color: #3b3b3b;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    color: #de1c26;
}
/*Dashboard Page*/
/*Service Register Quick Page*/
.h4_reg_quick
{
    margin-top: 30px;
    margin-bottom:30px;
}
.h5_reg_quick
{
    margin-top: 20px;
    margin-bottom: 20px;
}
.form-group_reg_quick 
{
    margin-bottom: 50px;
}
.btn-primary_reg_quick 
{
    color: #fff;
    background-color: #3b3b3b;
    border-color: #3b3b3b;
    width: 50%;
}
.btn-primary_reg_quick:hover, .btn-primary_reg_quick:focus {
    background-color: #ed1c24;
    background-position: 0 -15px;
    color:#fff;
    border-color: #ed1c24;
}
/*Service Register Quick Page*/
/*Footer*/
.footer-contact-box p 
{
    margin-bottom: 14px;
    padding-left: 45px;
    padding-bottom: 11px;
    font-family: 'Century Gothic';
}
/*Footer*/
/*Contact Page*/
.contact-info-page-contact 
{
    background: transparent;
    padding: 10px 100px;
    padding-bottom: 5px;
}
.item-contact-info::after 
{
    background: none;
    content: "";
    height:0px;
    left:0%;
    margin-left:0px;
    position:none;
    top: 0px;
    width: 0px;
}
.item-contact-info 
{
    position: relative;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 30px;
}
.p-contact
{
    margin-bottom: 50px;
}

.footer-contact-box .p-contact-1 
{
    margin-bottom: 25px;
    padding-left: 45px;
    padding-bottom: 11px;
    font-family: 'Century Gothic';
    color: #3a3a3c;
}
.footer-box .a-contact 
{
    color: #3a3a3c;
}
.sup-contact 
{
    top: -0.5em;
    position: relative;
    line-height: 0;
    vertical-align: baseline;
    font-size: 13px;
}  
/*Contact Page*/
/*Media Query*/
@media (min-width: 1024px){
.main-nav.main-nav1 
{
    margin: -90px 0px 0 95px;
    text-align: center;
}

.li-margin
{
    margin-left: 20px;
}

.mini-cart-box.mini-cart1 
{
    float: right;
    margin-top: 15px;
}
.banner-new.banner-ads6 
{
    margin-bottom: 40px;
    height: 500px;
}

.wecome-content 
{
    padding: 7% 0 0 40px;
}

/*.product-thumb .trail-img 
{
    width: 80%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 150px;
    margin: auto;
    display: block;
}*/


/*Total Order Show*/
 
 .h2-order-2 
 {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 60%;
}
.bt-style40-order 
{
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 30%;
    margin-left: 290px;
}

/*Total order Show*/

/*Kids Page*/
 .row-kids-bottom
 {
    margin-bottom: 10px;
 }
 .oval-image2
{
  
    width: 90%;
    margin-left: 75px;
}
/*Kids Page*/



}

@media(max-width:1024px){

.main-nav.main-nav1 > ul > li > .a-menu-left-2 
{
    margin-left: 40px;
}

/*.product-thumb .trail-img 
{
    width: 70%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 200px;
    margin: auto;
    display: block;
}*/

.oval-image
{
  
    width: 90%;

}
.countryy-image {
    width: 100%;
}
.icons-trail-tiny_locator2{

}
.aa-shop-trail {
    width: 85%;
   
}
.wecome-content h1 {
    text-align: center;
}
.wecome-content-trail a
{
    width: 100%;
    text-align: center;
}
.country-image {
    width: 100%;
}
.img-whybond 
{
    height: 400px;
}

.wecome-content-trail .a-shopnow-wearbond 
{
    margin: 22px 0 0;
}

.contact-info-page-contact 
{
    background: transparent;
    padding: 10px 80px;
    padding-bottom: 5px;
}

/*About Us Page ver -2*/

.img-our-mission 
{
    margin-left: 50px;
}

.img-voice 
{
    margin-left: -70px;
    height: 250px;
}

.container .btn-trail-our-mission 
{
    position: absolute;
    top: 35%;
    left: 56%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 2px 0px 2px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 60%;
}
/*About Us Page ver -2*/

/*My account page*/
.main-nav .sub-menu-myaccount {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    left: 0px;
    list-style: outside none none;
    margin: 30px 0 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 200px;
    z-index: 999;
    text-align: left;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}

/*My account page*/

/*Cart User Orders Page ver -2*/

.h2-order-2-ver-2 
{
    width: 57%;
}

/*Cart User Orders Page ver -2*/
/*Profile page*/
.h2-profile-1-h2 
{
    padding-left: 0px;
}

.img-profile-img
{

    margin-top: 65px;
}

/*Profile page*/

}
@media(max-width:768px){
.main-header.main-header1 
{
    padding: 0px 0 0;
}
.main-nav.main-nav1 > ul > li > .a-menu-left-1 
{
    margin-left: 40px;
}

.main-nav.main-nav1 > ul > li > .a-menu-left-2 
{
    margin-left: 40px;
}

.scroll-fixed.nav-fixed.nav-show 
{
    top: 0px;
}
.country-image {
    width: 100%;
}
.logo img 
{
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    margin-left: 270px;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
    height: 80px;
    margin-left: 300px;
}
.scroll-fixed.nav-fixed .main-nav.main-nav1 
{
    margin: 2px 0 0;
}

.scroll-fixed.nav-fixed .main-nav > ul > li > .a-menu-left-1 
{
    margin-left: 0px;
}

.scroll-fixed.nav-fixed .main-nav > ul > li > .a-menu-left-2 
{
    margin-left: 100px;
}

.mini-cart-box.mini-cart1 
{
    float: right;
    margin-top: 21px;
    padding-right: 20px;
}
.scroll-fixed.nav-fixed .mini-cart-box.mini-cart1
{
    margin-top: 10px;
    padding-left: 180px;
}
.mega-menu-v2 .content-mega-menu 
{
    border: medium none;
    margin-top: -4.0em;
    width: 70%;
}
.banner-new.banner-ads6 {
    margin-bottom: -20px;
    height: 500px;
}

.footer-trail-bottom {
    margin-bottom: 20px;
}

.wecome-content-trail .a-learnmore 
{
    display: inline-block;
    background-color: transparent;
    color: #3b3b3b;
    padding: 10px 15px;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 500;
    border: 1px solid #3b3b3b;
}

.wecome-content-trail .a-learnmore:hover 
{
    background-color: #d51c27;
    color: #fff;
    border:1px solid #fff;
}

.wecome-content-trail .a-shopnow 
{
    display: inline-block;
    background-color: #3a3a3c;
    color: #fff;
    padding: 10px 15px;
    border-radius: 25px;
    margin: 20px 10px 0;
    font-weight: 500;
}
.product-thumb .trail-img 
{
    width: 60%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 190px;
    margin: auto;
    display: block;
}

.p-ourproducts-1 
{
    text-align: center;
    margin-top: 3px;
    font-size: 17px;
}
.product-item-trail 
{
    margin-top: 165px;
}

/*Cart / Service Login Page New*/
.row-shadow 
{
    box-shadow: 0 0 3px #ccc;
    padding-bottom: 25px;
}

.form-group 
{
    margin-bottom: 15px;
    padding-left: 20px;
}

.form-partititon 
{
    padding-top: 40px;
    padding-bottom: 50px;
    
}
/*Cart / Service Login Page New*/

/*Products Page*/

.title48
{
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.product-thumb .img-product-page-trail 
{
    height: 250px;
    width: 40%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

/*Products Page*/

/*Kids Page*/
  .img-kids-bottom
 {
    margin-bottom:17px
 }

 .wecome-content-trail a 
 {
    display: inline-block;
    background-color: #c1272d;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    margin: 20px 0px 0 0px;
    font-weight: 500;
 }

 .img-spec 
 {
    display: block;
    align-items: center;
    margin: auto;
    height: 300px;
    /* margin-left: 50px; */
}
/*Kids Page*/






/*Kids Detail Page*/
   .product-extra-link.style2 a.addcart-link 
   {
     background: #3b3b3b none repeat scroll 0 0;
     border-radius: 4px;
     color: #fff;
     height: 36px;
     letter-spacing: 1px;
     line-height: 36px;
     padding-left: 53px;
     position: relative;
     text-align: left;
     width: 156px;
     margin-left: 0;
     border-radius: 50px;
     margin-bottom: 10px;
   }
/*Kids Detail Page*/

/*Cart User Account Page*/

._1GRhLX-3 
{
    padding: 15px 0px 50px 20px;
    margin-left: 10px;
    margin-right: 10px;
}

/*Cart User Account Page*/

/*Profile Page*/

.col-profile 
{
    padding-left: 0px;
    padding-right: 40px;
}

.a-profile 
{
    display: grid;
    margin-left: 170px;
    margin-top: 20px;
}

.a-profile-1 
{
    display: grid;
    margin-right: 170px;
    margin-top: 20px;
}

.p-skip 
{
    margin-left: 0px;
    color: #d51c27;
    margin-top: 15px;
    font-size: 18px;
    font-family: 'Roboto-Regular';
    padding-left: 0px;
    padding-right: 00px;
    text-align: center;
}
.col-profile-1 
{
    margin-left: auto;
    align-items: center;
    display: block;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-shadow: 3px 3px 3px 3px #f7f4f4;
    padding-left: 100px;
    padding-right: 100px;
}

.h2-profile-1-h2 
{
    padding-top: 40px;
    font-size: 40px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
}
.img-profile-img 
{
    background-color: #efebeb;
    padding: 15px 15px 15px 15px;
    border-radius: 25px;
    margin-top: 15px;
    margin: auto;
    display: block;
}
.h2-profile-h2 
{
    text-align: center;
}

.img-profile-2
{
    height: auto;
    padding: 10px;
    padding-right: 0px;
    margin: auto;
    align-items: center;
    display: block;
    background-color: #efebeb;
    border-radius: 85px;
}

.p-profile-p 
{
    text-align: center;
}
/*Profile Page*/

/*Total Order Show*/
  .h2-order-2 
  {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 70%;
 }

 .bt-style40-order 
 {
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 50%;
    margin-left: 100px;
 }

/*Total Order Show*/
/*Cart User Order Page ver-2*/
.h2-order-2-ver-2 
{
    width: 25%;
    padding: 3px 20px 3px 10px;
}
.h2-order-ver-2-1 
{
    font-family: 'Roboto-Regular';
    font-size: 25px;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
}
.cart-thumb-order-ver-2 
{
    display: block;
    overflow: hidden;
    width: 120px;
    margin-top: 15px;
    margin-left: 40px;
    margin: auto;
}
.h4-order-ver-2 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left: 0px;
}
.p-order-ver-2 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left:0px;
}
.p-order-ver-2-1 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left: 0px;
}
.p-order-ver-2-2 
{
    font-family: 'Roboto-Regular';
    text-align: center;
    padding-bottom: 10px;
    margin-left:0px;
}
/*Cart User Order Page ver-2*/

/*Success Page ver -2*/
.h2-success-ver-2 
{
    margin-left: 0px;
    text-align:center;
}

.h2-success-ver-2-1 
{
    padding-top: 40px;
    font-family: 'Roboto-Regular';
    font-size: 20px;
    text-align: center;
}
.p-success-ver-2 
{
    margin-left: 0px;
    font-family: 'Roboto-Regular';
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.p-1-success-ver-2 
{
    margin-top: 40px;
    font-size: 20px;
    background-color: #f1efef;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 160px;
    width: 40%;
    margin-left: 220px;
}

.strong-success-ver-2
{
    padding-right: 0px; 
    padding-left: 0px; 
}
.p-2 
{
    font-size: 20px;
    text-align: center;
}

/*Success Page ver -2*/

.wecome-content 
{
    padding: 1% 0 0;
}
.wecome-content-trail-1 {
    padding-left: 0px;
}
#core-features-area-trail-3 
{

    padding-top: 10px;
    padding-bottom: 20px;

}

video 
{
    display: block;
    background: #fff;
    width: 100%;
    margin: auto;
    height: auto;
}

.video-kids 
{
    display: block;
    background: #fff;
    width: 100%;
    margin: auto;
    height: 400px;
}

.styling_new 
{
    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 0px;
    padding-left: 0px;
}
.banner-text-index
{
    margin-top: 20px;
    margin-bottom: 50px;
}
.p-banner-mobile {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}
.h4-banner-mobile 
{
    font-size: 20px;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 10px;
}
.container .whybond-1 
{
    position: absolute;
    top: 20%;
    left: 75%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0px 0px;
    border: 0px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    opacity: 0.7;
    width: 220px;
}
.title30 {
    font-weight: 700;
    margin: 0;
    font-size: 30px;
    text-transform: capitalize;
    font-family: 'Century Gothic', sans-serif;
}

/*Login Page*/

/*About Us Page ver -2*/
.wecome-content-trail .a-about 
{
    display: inline-block;
    background-color: #fff;
    color: #c9141b;
    padding: 7px 25px;
    border-radius: 25px;
    margin: 90px 0 0;
    font-weight: 600;
    border: 1px solid #fff;
}

.img-our-mission 
{
    margin: auto;
    align-items: center;
    display: block;
    margin-bottom: 20px;
}

.img-voice 
{
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}
.container .btn-trail-our-mission 
{
    position: absolute;
    top: 35%;
    left: 43%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 35px 0px 35px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 50%;
}
.container .btn-trail-technology 
{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 1px 1px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 30%;
}
.container .btn-trail-voice 
{
    position: absolute;
    top: 30%;
    left: 55%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 35px 1px 35px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
}
/*About Us Page ver -2*/

/*Footer Newsletter*/
.h2-trail-4 
{

    text-align: center;
}
.p-footer
{
    text-align:center;
}

.newslettererror  
{
    color: #ed1c24;
    display: inline;
    font-weight: bolder;
    color: red;
    float: right;
    font-family: 'Century Gothic';
    font-size: 15px;
    font-weight: 500;
    font-style:none;
}

.sup-contact
{
    top: -0.5em;
    position: relative;
    line-height: 0;
    vertical-align: baseline;
    font-size: 13px;
}
/*Footer Newsletter*/

 /*My account page*/
    .main-nav .sub-menu-myaccount 
    {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
      left: 50px;
      list-style: outside none none;
      margin: 30px 0 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      visibility: hidden;
      width: 200px;
      z-index: 999;
      text-align: left;
      transition: all 0.5s ease-out 0s;
      -webkit-transition: all 0.5s ease-out 0s;
    }
    /*My account page*/
.scrollTop 
{
    right: 90%;
}


}
@media (max-width: 767px){
.main-header.main-header1 {
    padding: 0px 0;
}

.logo.logo1_new 
{
    width: 150px;
}
.country-image {
    width: 100%;
}

.logo.logo1 img {
    height: 100px;
    margin-left: 180px;
    text-align: center;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 90px;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 90px;
}

.main-nav.main-nav1 
{
    float: left;
    margin-top: -67px;
    text-align: left;
}
.main-nav .toggle-mobile-menu 
{
    height: 28.0px;
    width: 30px;
    position: relative;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
}
.main-nav .toggle-mobile-menu::after 
{
    content: '';
    position: absolute;
    top: 0;
    height: 5px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #fff;
}
.main-nav.active > ul 
{
    transform: translateX(0) !important;
    -webkit-transform: translateX(0) !important;
    background-color: #232121;
}
.main-nav > ul > li > a {
    color: #fff!important;
    font-weight: 400!important;
}
.main-nav.main-nav1 > ul > li > .a-menu-left-1 
{
    margin-left: 0px;
}
.main-nav.main-nav1 > ul > li > .a-menu-left-2 
{
    margin-left: 0px;
}
.border-menu
{
  border-top: 1px solid #e5e5e5;
}
#welcome-area-trail 
{
    padding: 25px 0;
    margin-bottom: 50px;
}
.content-shop 
{
    padding: 25px 0 50px;
}
.product-item_new
{
  margin-top: 25px;
}
/*Index Page*/
.banner-ads6 .btn-trail 
{
    position: relative;
    top: 40%;
    left: 20%;
    -ms-transform: translate(-50%, -50%);
    color: #616162;
    padding: 10px 30px;
    margin: -70px 10px 0 0;
    font-weight: 700;
    width: 500px;
}
.p-banner 
{
    font-size: 25px;
    font-weight: bold;
}
.h4-banner 
{
    font-size: 30px;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: -3px;
}
.strong-banner {
    font-size: 36px;
    font-weight: bold;
}
.banner-new.banner-ads6 
{
    margin-bottom: -50px;
    height: 500px;
}
.styling_new {
    padding-top: 0px;
    padding-right: 2px;
    padding-bottom: 20px;
    padding-left: 7px;
}
.img-whybond 
{
    height: auto;
}
.container .whybond-1 
{
    position: absolute;
    top: 30%;
    left: 80%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0px 0px;
    border: 0px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    opacity: 0.7;
    /* width: 300px; */
}

.form-input-register
{
   margin-top:0px; 
}

.wecome-content-trail button
{
    margin: 17px 0 17px;
}

.footer-trail-bottom
{
    margin-bottom:20px;
}
/*Index Page*/

/*Products Page*/
.title48 
{
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.product-thumb .img-product-page-trail 
{
    height: 210px;
    width: 70%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

.col-30px-padding
{
    padding-left: 30px;
    padding-right: 30px;
}

.col-20px-padding
{
    padding-left: 20px;
    padding-right: 20px;
}

/*Products Page*/

/*Shipping Page*/
.title-shipping-2 
{
    margin-top: 30px;
}

._1GRhLX-shipping 
{
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    padding: 25px 20px 20px 2px;
    margin-left: 0px;
    margin-right: 10px;
}

/*Shipping Page*/

/*Kids Page*/
  #core-features-area-trail-1 
  {
    padding: 0px 0 0;
    padding-bottom: 10px;
    margin-top:20px;
  }
  
.img-spec
{
    display:block;
    align-items: center;
    margin:auto;
    height:300px;
    margin-left:0px;
}
.container-trail-1-1 
{
    padding-left: 40px;
}

.h2-trail-acces 
{
    font-size: 40px;
}

/*Kids Page*/
/*Cart / Service Login Page New*/
.form-partititon
{
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 50px;
}

.title-shop-page-on-mobile 
{
    font-weight: 500;
    margin: -10px 0 10px;
    text-align: center;
    text-transform: capitalize;
}

#error-coupon
{
    margin-left: 0px;
    margin-right: 30px;
}
/*Cart / Service Login Page New*/

/*About Us Page*/
#core-features-area-trail-about-us-1 
{
    padding: 20px 0 0;
}

.title30-about-us-2-767 
{
    font-weight: 700;
    margin-top: -10px;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Century Gothic','Roboto-Regular';
    color: #3b3b3b;
    margin-bottom: 70px;
}

/*About Us Page*/


/*About Us Page ver -2 */
.wecome-content-trail .a-about 
{
    display: inline-block;
    background-color: #fff;
    color: #c9141b;
    padding: 5px 10px;
    border-radius: 25px;
    margin: 8px 0 0;
    font-weight: 600;
    border: 1px solid #fff;
}
.img-our-mission
{
    margin-left: 100px;
    margin: auto;
    display: block;
    align-items: center;
    margin-bottom: 20px;
}

.img-voice
{
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

.container .btn-trail-our-mission 
{
    position: absolute;
    top: 35%;
    left: 42%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 50%;
}

.container .btn-trail-voice 
{
    position: absolute;
    top: 30%;
    left: 60%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 0px 0px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
}

/*About Us Page ver -2*/

/*Cart User Account Page*/

._1GRhLX-3 
{
    padding: 15px 0px 50px 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.bt-loadmore-1 
{
    width: 250px;
}

/*Cart User Account Page*/

/*Profile Page*/
.title-shop-page-mobile-profile 
{
    font-weight: 500;
    margin: -10px 0 0px;
    text-align: center;
    text-transform: capitalize;
}

.col-profile 
{
    padding-left: 10px;
    padding-right: 30px;
}

.col-profile-1
{
    margin-left:0px;
    align-items: center;
    display: block;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-shadow: 3px 3px 3px 3px #f7f4f4;
    padding-left: 0px;
    padding-right: 0px;
}
.a-profile
{
    display: grid;
    margin-left: 30px;
    margin-top: 20px;
}

.a-profile-1
{
    display: grid;
    margin-right: 30px;
    margin-top: 20px;
}

.p-skip 
{
    margin-left: 0px;
    color: #d51c27;
    margin-top: 15px;
    font-size: 18px;
    font-family: 'Roboto-Regular';
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
}

.img-profile-img
{
    margin: auto;
    align-items: center;
    display: block;
}

.p-profile-p 
{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.h2-profile-h2 
{
    font-size: 20px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.img-profile-2 
{
    padding-top: 0px;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 23px;
}

/*Profile Page*/

/*Cart User Order Page ver-2*/
.h2-order-2-ver-2 
{
    width: 50%;
    padding: 3px 20px 3px 10px;
}
.h2-order-ver-2-1 
{
    font-family: 'Roboto-Regular';
    font-size: 25px;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
}
.cart-thumb-order-ver-2 
{
    display: block;
    overflow: hidden;
    width: 120px;
    margin-top: 15px;
    margin-left: 40px;
    margin: auto;
}
.h4-order-ver-2 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left: 0px;
}
.p-order-ver-2 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left:0px;
}
.p-order-ver-2-1 
{
    margin-top: 15px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-left: 0px;
}
.p-order-ver-2-2 
{
    font-family: 'Roboto-Regular';
    text-align: center;
    padding-bottom: 10px;
    margin-left:0px;
}
/*Cart User Order Page ver-2*/
/*Success Page ver -2*/
.h2-success-ver-2 
{
    margin-left: 0px;
    text-align:center;
}

.h2-success-ver-2-1 
{
    padding-top: 40px;
    font-family: 'Roboto-Regular';
    font-size: 20px;
    text-align: center;
}
.p-success-ver-2 
{
    margin-left: 0px;
    font-family: 'Roboto-Regular';
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.p-1-success-ver-2 
{
    margin-top: 40px;
    font-size: 20px;
    background-color: #f1efef;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 160px;
    width: 70%;
    margin-left: 50px;
}

.strong-success-ver-2
{
    padding-right: 0px; 
    padding-left: 0px; 
}
/*Success Page ver -2*/
/*Footer*/
.social-footer 
{
    font-size: 0;
    margin-bottom: 15px;
    margin-top: -1px;
    text-align: center;
}
/*Footer*/
.mega-menu-v2 .content-mega-menu 
{
    border: medium none;
    margin-top: -19.0em;
    width: 60%;
}
.mini-cart-box.mini-cart1 
{
    float: right;
    margin-top: 15px;
}

.bt-style40-order 
{
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 70%;
    margin-left: 50px;
}
.h2-order-2 
{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 70%;
}
.h2-trail-1 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 20px;
}
.h2-trail-2 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    padding-top:0px;
}
.h2-trail-3 
{
    font-size: 30px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 30px;
}

.h2-trail-4 
{
    text-align: center;
}

.p-footer
{
    text-align: center;
}

.newslettererror  
{
    color: #ed1c24;
    display: inline;
    font-weight: bolder;
    color: red;
    float: right;
    font-family: 'Century Gothic';
    font-size: 13px;
    font-weight: 500;
    font-style:none;
}

/*Contact page*/
.title-contact-page 
{
    font-weight: 500;
    margin: 20px 0 30px;
    text-align: center;
    text-transform: capitalize;
}
/*Contact page*/
.scrollTop 
{
    right: 85%;
}

}

@media(max-width:736px){
/*Index Page*/
  /*Logo Center*/
     .logo.logo1 img 
     {
       height: 100px;
       margin-left: 250px;
       text-align: center;
     }
  /*Logo Center*/
  .p-ourproducts-1 
  {
    text-align: center;
    margin-top: 3px;
    font-size: 17px;
  }
  .country-image {
    width: 100%;
}
.product-item-trail 
{
    margin-top: 165px;
}
/*Index Page*/

/*Products Page*/

.product-thumb .img-product-page-trail 
{
    height: 250px;
    width: 50%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

/*Products Page*/

/*Kids Page*/
.img-spec 
{
    display: block;
    align-items: center;
    margin: auto;
    height: 300px;
}
/*Kids Page*/

/*Cart Page*/
#error-coupon 
{
    margin-left: 0px;
    margin-right: 220px;
}

/*Cart Page*/

/*About Us Page ver -2 */
.wecome-content-trail .a-about 
{
    display: inline-block;
    background-color: #fff;
    color: #c9141b;
    padding: 7px 25px;
    border-radius: 25px;
    margin: 70px 0 0;
    font-weight: 600;
    border: 1px solid #fff;
}
.container .btn-trail-our-mission
 {
    position: absolute;
    top: 30%;
    left: 42%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 10px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 50%;
}

.container .btn-trail-voice 
{
    position: absolute;
    top: 30%;
    left: 60%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 30px 0px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
}
/*About Us Page ver -2*/

/*Profile Page*/
.a-profile 
{
    display: grid;
    margin-left: 150px;
    margin-top: 20px;
}

.a-profile-1 
{
    display: grid;
    margin-right: 150px;
    margin-top: 20px;
}
.h2-profile-1-h2 
{
    padding-top: 40px;
    font-size: 40px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
}

.img-profile-2 
{
    height: auto;
    padding: 10px;
    padding-right: 0px;
    margin: auto;
    align-items: center;
    display: block;
    background-color: #efebeb;
    border-radius: 85px;}

.wecome-content-trail .a-next-a 
{
    margin-left: 40px;
}
/*Profile Page*/
/*Cart User Order Page ver-2*/
.h2-order-2-ver-2 
{
    width: 28%;
    padding: 3px 20px 3px 7px;
}
/*Cart User Order Page ver-2*/

/*Success Page ver -2*/
.p-1-success-ver-2 
{
    margin-top: 40px;
    font-size: 20px;
    background-color: #f1efef;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 160px;
    width: 40%;
    margin-left: 210px;
}

.p-2 
{
    font-size: 20px;
    text-align: center;
}

/*Success Page ver -2*/
/*Footer Newsletter*/
.newsletter-form-trail 
{
    text-align: center;
    margin: auto;
}

/*Footer Newsletter*/
}

@media(min-width:734px){

/*Kids Page*/
/*.p-2-trail 
{
    color: #3b3b3b;
    font-size: 16px;
    width: 70%;
    text-align: center;
    margin: auto;
}

video 
{ 
    width: 70%;
}

.core-feature-content h4 
{
    text-align: center;
}

.styling_new 
{
    padding-top: 0px;
    padding-right: 2px;
    padding-bottom: 0px;
    padding-left: 7px;
    margin-bottom: -54px;
}

.img-margin-1
{
    margin-left: 80px;
    width: 70%;
}

.img-help
{
    margin-left: -20px;
}

#core-features-area-trail-1 {
    padding: 7px 0 0;
    padding-bottom: 10px;
    margin-top: 10px;
}

.styling_new-help 
{
  padding-bottom: 50px;
}

.styling_new-live 
{
    margin-top: -50px;
    text-align: center;
    margin-bottom: 24px;
}
.h2-trail-3 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
}

.img-live
{
  margin-left: 180px;
}

.styling_new-modish 
{
    margin-bottom: 10px;
}

.p-family
{
    width: 70%;
    margin: auto;
}
.styling-how-to-wear 
{
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 15px;
    text-align: center;
    margin-top: 60px;
}
.core-features-in-the-box
{
    padding: 7px 0 0;
    padding-bottom: 0px;
    margin-bottom: -50px;
    margin-top: 70px;
    margin-bottom: 20px
}*/
/*Kids Page*/

}
@media(max-width:684px){

/*Logo Center*/
.logo.logo1 img 
{
        height: 100px;
        margin-left: 200px;
        text-align: center;
}

.scroll-fixed.nav-fixed .logo.logo1 img 
{
    height: 90px;
    margin-left: 200px;
}

.scroll-fixed.nav-fixed .main-nav.main-nav1 
{
    margin: -60px 0 0;
}
/*Logo Center*/

.title30 
{
    font-weight: 700;
    margin: 0;
    font-size: 25px;
    text-transform: capitalize;
    font-family: 'Century Gothic', sans-serif;
}
.country-image {
    width: 100%;
}
/*Kids Pagee*/
/*.img-margin-1
{
       margin-left: -30px;
}

.img-help
{
    margin-left: -10px;
}

.img-live
{
    margin: auto;
}
.img-modish
{
  margin-left: -15px;
}*/
/*Kids Page*/

/*Total Order Show*/
 .h2-order-2 
 {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 80%;
 }

/*Total Order Show*/
}
@media(max-width:465px){
.logo.logo1 img 
{
    height: 100px;
    margin-left: 100px;
    text-align: center;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 90px;
  margin-left: 100px;
}
.mega-menu-v2 .content-mega-menu {
    border: medium none;
    margin-top: -19.0em;
    width: 50%;
}
.main-nav.active > ul 
{
    transform: translateX(0) !important;
    -webkit-transform: translateX(0) !important;
    background-color: #232121;
}
.main-nav > ul > li > a {
    color: #fff!important;
    font-weight: 400!important;
}
.banner-new.banner-ads6 
{
    margin-bottom: -140px;
    height: 500px;
}
.p-ourproducts-1 
{
    text-align: center;
    margin-top: 3px;
    font-size: 17px;
}
.banner-ads6 .btn-trail {
    position: relative;
    top: 40%;
    left: 15%;
    -ms-transform: translate(-50%, -50%);
    color: #616162;
    padding: 10px 30px;
    margin: -30px 10px 0 0;
    font-weight: 700;
    width: 800px;
}
/*Cart / Service Login Page New*/
.td-extra 
{
    padding-right: 0px;
}
.form-partititon
{
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 50px;
}
/*Cart / Service Login Page New*/
/*Contact page*/
.title-contact-page 
{
    font-weight: 500;
    margin: 20px 0 30px;
    text-align: center;
    text-transform: capitalize;
}
.contact-info-page-contact 
{
    background: transparent;
    padding: 10px 20px;
    padding-bottom: 5px;
}
/*Conatct page*/
}


@media(max-width:414px){

.product-thumb .trail-img 
{
    width: 60%;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    height: 210px;
    margin: auto;
    display: block;
}

.product-item_new 
{
    margin-top: 25px;
}

/*Products Page*/
.product-thumb .img-product-page-trail 
{
    height: 210px;
    width: 60%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

/*Products Page*/

/*Kids Page*/
.wecome-content 
{
    padding: 1% 0 0;
    margin: auto;
    text-align: center;
}
.p-text-top
{
    margin: auto;
    text-align: center;
    width: 90%;
    margin-bottom: 20px;
}

.styling 
{
    padding-top: 10px;
    padding-right: 26px;
    padding-left: 26px;
    padding-bottom: 15px;
    text-align: center;
}

.img-margin-1
{
    margin-left: -15px;
}

.img-help
{
    margin-left: -15px;
}

.img-modish
{
  margin-left: -15px;
}

.img-spec 
{
    display: block;
    align-items: center;
    margin: auto;
    height: 300px;
    margin-left:0px;
}
/*Kids Page*/

/*Kids Detail Page*/
.product-extra-link.style2 a.addcart-link 
{
    background: #3b3b3b none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    letter-spacing: 1px;
    line-height: 36px;
    padding-left: 53px;
    position: relative;
    text-align: left;
    width: 156px;
    margin-left: 0;
    border-radius: 50px;
    margin-bottom: 10px;
}
/*Kids Detail Page*/

/*Cart Page*/
#error-coupon 
{
    margin-left: 0px;
    margin-right: 40px;
}

/*Cart Page*/

/*About Us Page ver -2*/
.wecome-content-trail .a-about 
{
    display: inline-block;
    background-color: #fff;
    color: #c9141b;
    padding: 5px 10px;
    border-radius: 25px;
    margin: 10px 0 0;
    font-weight: 600;
    border: 1px solid #fff;
}

.container .btn-trail-technology 
{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 10px 1px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 60%;
}

.container .btn-trail-voice 
{
    position: absolute;
    top: 30%;
    left: 60%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 10px 0px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 50%;
}

/*About Us PAge ver -2*/

/*Profile Page*/

.col-profile 
{
    padding-left: 10px;
    padding-right: 30px;
}

.a-profile 
{
    display: grid;
    margin-left: 30px;
    margin-top: 20px;
}

.a-profile-1 
{
    display: grid;
    margin-right: 30px;
    margin-top: 20px;
}
.h2-profile-1-h2 
{
    padding-top: 40px;
    font-size: 40px;
    font-family: 'Roboto-Regular';
    font-weight: 600;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
}

.img-profile-2 
{
    height: auto;
    padding: 10px;
    padding-right: 0px;
    margin: auto;
    align-items: center;
    display: block;
    background-color: #efebeb;
    border-radius: 85px;
}
.wecome-content-trail .a-next-a 
{
    margin-left:40px;
}
/*Profile Page*/

/*Total Order Show*/
 .h2-order-2 
 {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 130%;
 }

/*Total Order Show*/

/*Cart User Order Page ver-2*/

.h2-order-2-ver-2 
{
    width: 54%;
    padding: 3px 20px 3px 7px;
}

/*Cart User Order Page ver-2*/

/*Success Page ver -2*/
.h2-success-ver-2 
{
    margin-left: 0px;
    text-align:center;
}

.h2-success-ver-2-1 
{
    padding-top: 40px;
    font-family: 'Roboto-Regular';
    font-size: 20px;
    text-align: center;
}
.p-success-ver-2 
{
    margin-left: 0px;
    font-family: 'Roboto-Regular';
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.p-1-success-ver-2 
{
    margin-top: 40px;
    font-size: 20px;
    background-color: #f1efef;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 160px;
    width: 70%;
    margin-left: 50px;
}

.strong-success-ver-2
{
    padding-right: 0px; 
    padding-left: 0px; 
}

.p-2 
{
    font-size: 20px;
    text-align: center;
}
/*Success Page ver -2*/

}
@media(max-width:390px){
    .mega-menu-v2 .content-mega-menu {
    border: medium none;
    margin-top: -19.0em;
    width: 40%;
}
}
@media (max-width: 380px){
.logo.logo1 img {
    height: 90px;
    text-align: center;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 90px;
  margin-left: 90px;
}
.mini-cart-box.mini-cart1 
{
    float: right;
    margin-top: 15px;
    padding-right: 12px;
}
.main-nav.main-nav1 {
    float: left;
    margin-top: -60px;
    text-align: left;
}
.mega-menu-v2 .content-mega-menu 
{
    border: medium none;
    margin-top: -19.0em;
    width: 40%;
}
.main-nav.active > ul 
{
    transform: translateX(0) !important;
    -webkit-transform: translateX(0) !important;
    background-color: #232121;
}
.main-nav > ul > li > a {
    color: #fff!important;
    font-weight: 400!important;
}
	.banner-slider .banner-info{
		display:block;
	}
    .banner-new.banner-ads6 
   {
     margin-bottom: -180px;
     height: 500px;
   }
.banner-ads6 .btn-trail 
{
    position: static;
    top: 40%;
    left: 20%;
    -ms-transform: translate(-50%, -50%);
    color: #616162;
    padding: 10px 30px;
    margin: -20px 10px 0 0;
    font-weight: 700;
    width: 500px;
}
.styling_new 
{
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 0px;
}
.p-banner 
{
    font-size: 17px;
    font-weight: bold;
}
.h4-banner 
{
    font-size: 20px;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: -3px;
}
.strong-banner {
    font-size: 28px;
    font-weight: bold;
}
.container .whybond-1 
{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 0px 0px;
    border: 0px solid #fff;
    border-radius: 25px;
    margin: 20px 0 0;
    font-weight: 700;
    opacity: 0.7;
    width: 300px;
}
.h2-trail-2 
{
    font-size: 35px;
    color: #3b3b3b;
    font-weight: bold;
    padding-top:0px;
}
.title30 
{
    font-weight: 700;
    margin: 0;
    font-size: 25px;
    text-transform: capitalize;
    font-family: 'Century Gothic', sans-serif;
}
.newsletter-form > form 
{
    border-radius: 4px;
    padding-right: 10px;
    position: relative;
}
}
@media(max-width:375px){


/*Products Page*/

.product-thumb .img-product-page-trail 
{
    height: 210px;
    width: 70%;
    margin: auto;
    align-items: center;
    display: block;
    margin-top: 20px;
}

/*Products Page*/

/*Kids Page*/
.wecome-content 
{
    padding: 1% 0 0;
    margin: auto;
    text-align: center;
}
.p-text-top
{
    margin: auto;
    text-align: center;
    width: 90%;
    margin-bottom: 20px;
}

.img-margin-tiny
  {
    margin-left: -20px;
  }

  .img-help 
  {
    margin-left: -10px;
  }

  .img-modish
  {
    margin-left: -20px;
  }

/*Kids Page*/

/*Cart / Service Login Page New*/
.form-partititon
{
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 50px;
}
/*Cart / Service Login Page New*/

/*Total Orders Show*/
.h2-order-2 
{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 20px 5px 10px;
    width: 150%;
}

.bt-link 
{
    padding: 10px 0px;
}

.bt-style40-order 
{
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 50%;
    margin-left: 70px;
}
/*Total Orders Show*/

/*About Us Page ver -2*/
.container .btn-trail-our-mission 
{
    position: absolute;
    top: 35%;
    left: 42%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 10px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 60%;
}

.container .btn-trail-technology 
{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    padding: 0px 1px 1px 0px;
    border-radius: 25px;
    margin: 20px 0 0;
    cursor: pointer;
    opacity: 1;
    width: 60%;
}

/*About Us Page ver -2*/
/*Contact page*/
.title-contact-page 
{
    font-weight: 500;
    margin: 20px 0 30px;
    text-align: center;
    text-transform: capitalize;
}
.contact-info-page-contact
{
    background: transparent;
    padding: 10px 15px;
    padding-bottom: 5px;
}
/*Conatct page*/
}
@media(max-width:320px){
    .logo.logo1 img {
    height: 90px;
    text-align: center;
    margin-left: 70px;
}
.scroll-fixed.nav-fixed .logo.logo1 img 
{
  height: 90px;
  margin-left: 70px;
}
/*Cart / Service Login Page New*/
.form-partititon
{
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 50px;
}
/*Cart / Service Login Page New*/

/*Total Order Show*/

.h2-order-2 
{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #414042b5;
    color: #fff;
    padding: 10px 0px 5px 1px;
    width: 160%;
}

.bt-style40-order 
{
    color: #fff;
    background: #414042;
    box-shadow: 0 0 0 2px #d01c28 inset;
    margin-top: 15px;
    width: 42%;
    margin-left: 72px;
}
/*Total order Show*/
}
@media(max-width: 990px) and (min-width: 794px){
    .btn-primary-990 {
    color: #fff;
    background-color: #3a3a3c;
    border-color: #3a3a3c;
    background-image: none;
    width: 50%;
    align-items: center;
    margin: auto;
}
.country-image {
    width: 100%;
}

}
/*Media Query*/

 @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) 
  {
    
  }

    /* Portrait*/
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) 
     {
        .info-customer-saying {
    padding-right: 0px;
}
.yt-border {
    margin: 0 -10px;
}
.oval-image2 {
    width: 60%;
    margin-left: 307px;
}
.oval-image {
    width: 60%;
}
.gallery-box:before {
   
    top: 20px;
    height: 69%;
}
.gallery-box img {
       margin-top: 30px;
}
.p-22-trail {
    max-width: 70%;
    margin: 0 auto!important;
}

.aa-shop-trail {
    width: 50%;
    margin: 34px 170px 0;
}
.row-spec {
  
    width: 80%;
    margin-left: 165px;
}
.row-spec-1 {
  
    width: 80%;
}

.p-222-trail {
    max-width: 70%;
    margin: 30px auto!important;
}
.someFrame2 {
  width: 70%!important;
    margin-left: 120px;
     height: 300px!important;
}
.ul-In-the-box
{
    margin: 21px 100px 0;
}
     }

.desktop-middle22 {
    border-radius: 20px;
}


      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape)  {}

    /* Portrait*/
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) 
     {
        .oval-image2 {
    margin-left: 415px;
}
.aa-shop-trail {
    margin: 34px 260px 0;
}

.ul-In-the-box
{
    margin: 21px 140px 0;
}
.yt-border {
    margin: 0 30px;
}
.someFrame2 {
  width: 70%!important;
    margin-left: 160px;
}
.row-spec {
    width: auto;
    margin-left: 0px;
}
.row-spec-1 {
    width: auto;
}
     }

.form-affiliate-input {
    width: 50%;
    min-width: 300px;
}


.tb-box {
  transition: box-shadow .3s;
}

.tb-box:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,.18);
}

.tb-font-teal {
    color: #0aa595 !important;
}

.tb-font-lightteal {
    color: #33cccc !important;
}

.tb-font-lightblue {
    color: #27a9d5 !important;
}

.tb-font-lightgreen {
    color: #99cc00 !important;
}

.tb-font-white {
    color: white !important;
}

.tb-font-lightestblack {
    color: #777 !important;
}

.tb-font-lighterblack {
    color: #555 !important;
}

.tb-banner 
{
    background-size: contain;
    background-repeat:no-repeat;
    padding-bottom: auto;
    background-position: center; 
    border: 2px solid #f5f5f5; 
    background-color:#f5f5f5;
}

.tb-button 
{
    display: inline-block !important;
    padding: 10px 20px !important;
    border-radius: 25px !important;
    margin: 20px 0 0 !important;
    font-weight: 500 !important;
    font-size: 15px!important;
    box-shadow: 0 8px 16px rgba(0,0,0,.38);
}

.tb-button:hover 
{
    box-shadow: 0 8px 16px rgba(0,0,0,.58);
}

.tb-h1 {
    font-size: 72px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    color: #777;    /*default tb-font-lightestblack*/
}

/* Default styles for less than 764px */
.tb-h2 {
    font-size: 30px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    color: #777;
}

/* Styles for greater than 764px */
@media (min-width: 765px) {
    .tb-h2 {
        font-size: 50px;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        color: #777;
    }
}

.tb-h3 {
    font-size: 40px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    color: #555;    /*default tb-font-lighterblack*/
}

.tb-h4 {
    font-size: 26px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

.tb-h5 {
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

.tb-h6 {
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

.tb-todo {
    background-color: red!important;
}

.tb-icon
{
    width: 42px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
}

@media (min-width: 765px) {
    .tb-icon {
        width: 60px;
        height: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.tb-wd-100vp {
    width: 100%;
    height: auto;
    margin: auto;
}

.tb-row-flex {
    display: flex;
    flex-wrap: wrap;
}

.tb-features-card {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     /* Aligns content from top to bottom and stretches them */
    height: 100%; /* Makes each card stretch to fill the container */
}

.tb-button-text-uppercase {
    text-transform: uppercase; 
    text-align: center;
}


.bottom-sticky-bar-button-text {
    font-size: 15px !important;
    text-transform: uppercase;
}

@media (max-width: 429px) {

    .bottom-sticky-bar-button-text {
        font-size: 11px !important;
        text-transform: uppercase;
    }
    
}

@media (max-width: 350px) {

    .bottom-sticky-bar-button-text {
        font-size: 9px !important;
        text-transform: uppercase;
    }
    
}

 /* carousel */
 #quote-carousel {
    padding: 0 10px 30px 10px;
    margin-top: 30px;
}

/* Control buttons  */
#quote-carousel .carousel-control {
    background: none;
    color: #222;
    font-size: 2.3em;
    text-shadow: none;
    margin-top: 30px;
}

.circular-image-wrapper {
    display: inline-block;
    padding: 3px;
    /* Adjust the padding as needed */
    background-color: transparent;
    /* Background color for the padding space */
    border-radius: 50%;
    /* Makes the wrapper circular */
    border: 5px solid #E40D36;
    /* Adds a black border, change the color and width as needed */
}

/* Changes the position of the indicators */
#quote-carousel .carousel-indicators {
    right: 50%;
    top: auto;
    bottom: 0px;
    margin-right: -19px;
}

/* Changes the color of the indicators */
#quote-carousel .carousel-indicators li {
    background: #c0c0c0;
    height: 8px !important;
    width: 8px !important;
}

#quote-carousel .carousel-indicators .active {
    background: #E40D36;
    height: 10px !important;
    width: 10px !important;
}

#quote-carousel img {
    width: 250px;
    height: 100px
}

/* End carousel */

.item blockquote {
    border-left: none;
    margin: 0;
}

.item blockquote p:before {
    content: "\f10d";
    font-family: 'Fontawesome';
    float: left;
    margin-right: 10px;
}



/**
MEDIA QUERIES
*/

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #quote-carousel {
        margin-bottom: 0;
    }
}

/* Small devices (tablets, up to 768px) */
@media (max-width: 768px) {

    /* Make the indicators larger for easier clicking with fingers/thumb on mobile */

    #quote-carousel .carousel-indicators {
        bottom: -20px !important;
    }

    #quote-carousel .carousel-indicators li {
        margin: 0px 5px;
    }
}

.ratingsCarousel-inner .item.left.active {
    transform: translateX(-33%);
}

.ratingsCarousel-inner .item.right.active {
    transform: translateX(33%);
}

.ratingsCarousel-inner .item.next {
    transform: translateX(33%)
}

.ratingsCarousel-inner .item.prev {
    transform: translateX(-33%)
}

.ratingsCarousel-inner .item.right,
.ratingsCarousel-inner .item.left {
    transform: translateX(0);
}

/* Custom SEO h1 Style */
.seo-h1{
    position: absolute;
    left: -9999px;
    top: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}